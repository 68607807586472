.email-section {
    display: flex;
    flex-direction: row;
    padding:40px;
    /* flex-wrap: wrap-reverse; */
    /* padding-right: 5%; */
    margin-bottom: -90px;
    margin-top: -40px;
}
/* @media (max-width:600px) {
    .email-section {
            margin-top: -140px !important;  
    }
} */

.email-editor {
    flex: 1;
    padding: 20px;
    margin: 20px;
    border-radius: 38px;
    /* box-shadow: 1px 1px #cfcfcf; */
    /* box-shadow: 11px 7px 8px 4px #ddebf733; */
    box-shadow: 0px 0px 20px 3px #0f6ad417;
    margin-top: 60px;

}

.email-article {
    display: flex;
    flex-direction: column;
    flex: 1;
    direction: rtl;
    position: relative;
}

.email-sec {
    display: flex;
    flex-direction: flex-start;
    align-self: flex-start;
}

.thinking-img {
    width: 400px;
    height: 346px;
    align-self: flex-end;
    /* position: absolute;
    left: 0;
    bottom: 20%; */
}

.email-form {
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.email-input {
    width: 100%;
    margin: 10px;
    display: flex;
    gap: 10px;
}

/* .email-input label {
    flex: 1;
} */

.email-input input {
    /* flex: 4; */
    border: none;
    outline: unset;
    background-color: transparent;
    border-bottom: 1px solid rgb(0 0 0 / 48%);
    width: 60%;
}

.ql-editor {
    height: 300px !important;
}

.ql-toolbar.ql-snow {
    border: none !important;
}

.ql-container.ql-snow {
    border: none !important;
}

.email-form-button {
    background-color: #1AC741;
    border: none;
    width: 30%;
    align-self: center;
    height: 30px;
    margin: 20px;
    color: white;
    border-radius: 10px;
    cursor: pointer;
}

.email-end-button {
    background-color: #7A14FF;
    border: none;
    width: 30%;
    align-self: center;
    height: 50px;
    margin: 20px;
    color: white;
    border-radius: 40px;
    cursor: pointer;
}

.subject-arrow {
    position: relative;
}

.subject-arrow-img {
    position: absolute;
    right: -192px;
    width: 198px;
    bottom: 28px;
    transform: rotate(-10deg);
}

.emaildiv {
    width: 60%;
}
@media (max-width: 900px) {
    .subject-arrow-img {
        display: none;
    }
}
@media (max-width: 550px) {
    .email-editor{
        margin: 0;
        border-radius: 25px ;
    }

    /* .email-header {
            align-self: center;
    } */
    .email-section {
        flex-direction: column-reverse;
        padding: 20px;
    }

    .thinking-img {
        width: 300px;
        align-self: center;
    }

    .subject-arrow-img {
        display: none;
    }

    .email-form {
        gap: 10px;
    }

    .email-input {
        gap: 10px;
    }

    .email-form-button {
        width: 60%;
    }

    .emaildiv {
        width: 100%;
    }

    .h1eh {
        align-self: right !important;
        /* text-align: right !important; */
    }

    .thinking-img {
        height: 100%;
    }
}
.ql-snow .ql-picker-options {
    display: none !important;
}
.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    display: flex !important;
    gap: 10px;
    flex-direction: column;
    overflow: auto;
}

.email-editor-hidden>* {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: max-height 0.15s, visibility 0s, opacity 0.5s ease-out;
    overflow: hidden;
}

.ql-container {
    display: none;
}

.email-editor-visible>* {
    /* padding: 8px; */
    /* max-height: 500px; */
    transition: max-height 2.15s, visibility 2s, opacity 2.5s ease-out;
    visibility: visible;
    opacity: 1;
}

@media (max-width: 422px) {
    .btn.common-btn {
        padding: 10px 40px;
    }
    .sign_up_tools {
        padding: 0px !important ;
      }
}