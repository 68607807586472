.blog-section {
    width: 100%;
    min-height: 550px;
    background-repeat: no-repeat;
    background-size: contain;
    gap: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0px 20px 0px;
    text-align: center;
}

.blog-space {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 200px;
}

.blog-typer {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: 0;
    width: 27%;
    margin-bottom: 40px;
    margin-right: 20px;
}

.h1blog {
    font-size: 3rem;
}

.blog-subtext {
    font-size: 1rem;
    color: rgb(104, 114, 125);
}

.blog-input {
    display: flex;
    flex-direction: column;
    width: 70%;
    height: 100px;
    background-color: white;
    z-index: 2;
    border-radius: 20px;
    box-shadow: 2px 2px 20px 2px #00000014;
}

.blog-title-div {
    padding: 20px;
    flex: 1;
}

.blog-generate-div {
    flex: 6;
    margin: 20px 0 20px 0;
}
.blog-article {
    width: 90%;
}
.blog-title-input {
    display: flex;
    /* flex-direction: row-reverse; */
    justify-content: flex-start;
    margin: 10px;
    gap: 10px;
}

.blog-title-input button,
input {
    border: none;
}

.blog-title-input input {
    width: 100%;
    box-shadow: 0 0 12px #0000001c;
    border-radius: 10px;
    padding: 5px;
    margin-left: 5px;
    padding-top: 9px;
    padding-left: 10px;
}

.blog-title-input button {
    background-color: #1AC741;
    box-shadow: 2px 2px 2px 2px #ebe6e6bd;
    ;
    border: none;
    width: 30%;
    align-self: center;
    height: 30px;
    color: white;
    border-radius: 10px;
    cursor: pointer;
}


.blog-generate-div {
    flex: 6 1;
    border-radius: 26px;
    /* border: black; */
    border: 1px solid #f5efef;
    width: 90%;
    align-self: center;
    padding: 10px;
}

.blog-empty-div {
    flex: 1;
}

.blog-input-generated {
    height: 400px;
    transition: height 2.15s ease-out;
}

.blog-input .blog-generate-div {
    visibility: hidden;
    opacity: 0;
    /* overflow: hidden; */
    overflow-y: auto;
    border-radius: 26px;
}

.blog-input-generated .blog-generate-div {
    transition: visibility 3s, opacity 3.5s ease-out;
    visibility: visible;
    opacity: 1;
}


@media only screen and (max-width: 650px) {
    .blog-section{
        min-height: 300px !important;
        padding: 20px 0 !important;
    }
}
@media only screen and (max-width: 1050px) {
    .blog-input {
        width: 100%;
    }
}
@media only screen and (max-width: 767px) {
    .line_effect {
        height: 2px !important;
        width: 80% !important;
    }
    .count_wrapper {
        gap: 10px;
    }
    .or_effect {
        flex-direction: row;
    }
    .tweet-input {
        width: 90%;
    }

}
.waves {
    position: relative;
    width: 100%;
    height: 13vh;
    margin-bottom: -7px;
    /*Fix for safari gap*/
    min-height: 100px;
    max-height: 150px;
}

.parallax>use {
    animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
    animation-delay: -2s;
    animation-duration: 10s;
}

.parallax>use:nth-child(2) {
    animation-delay: -3s;
    animation-duration: 13s;
}

.parallax>use:nth-child(3) {
    animation-delay: -4s;
    animation-duration: 16s;
}

.parallax>use:nth-child(4) {
    animation-delay: -5s;
    animation-duration: 27s;
}

@keyframes move-forever {
    0% {
        transform: translate3d(-90px, 0, 0);
    }

    100% {
        transform: translate3d(85px, 0, 0);
    }
}

#gradient-canvas {
    --gradient-color-1: #1171d8;
    --gradient-color-2: #7a14ff;
    --gradient-color-3: #8fc3f3;
    --gradient-color-4: #c7a1ff;
}