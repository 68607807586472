.b2bgptE,
.b2bgptA {
    background-color: rgb(255, 255, 255);
    width: 90%;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
    border-radius: 20px;
    padding: 25px 10px;
    max-width: 500px;
    margin: auto;


    h1 {
        font-size: 1.5em;
        background: linear-gradient(90.33deg, #1877F2 7.21%, #68AAFF 92.99%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
        text-align: center;
        font-weight: bold;
    }

    p {
        font-size: 0.7em;
        text-align: center;
        color: #656565;
        font-weight: 400;
    }
}

.gptTop {
    background: #FBFBFB;
    border-radius: 20px;

    .services {
        padding: 20px;
        display: flex;
        justify-content: space-between;

        h3 {
            border-radius: 5px;
            padding: 8px;
            margin: 0;
            color: black;
            font-size: 0.7em;
            font-weight: 300;
            border: 1px solid #97C4FF;
            position: relative;
            align-self: baseline;

            &:nth-child(2) {
                bottom: 15px;
            }
        }

    }
}

.quetions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 20px 0;
    padding: 0 20px;



    h5 {
        font-size: 0.7em;
        margin: 0;
        text-align: right;
        font-weight: 400;
        padding: 0 10px;
    }

    span {
        background: #F7FCFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        padding: 10px;

        img {
            width: 30px;
        }
    }
}

.answer {
    padding: 20px;
    background-color: #F3F8FF;
    display: flex;

    span {
        height: 50px;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        padding: 10px;

        img {
            width: 35px;
        }
    }

    ul {
        font-size: 0.7em;
        padding: 0 20px;

        li {
            list-style-type: decimal;
            padding: 10px 0;
        }
    }
}

.input {
    background-color: #FBFBFB;
    border-radius: 10px;

    padding: 10px;

    >div {
        background-color: white;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;

        input {
            background-color: transparent;
            font-size: 0.8em;
        }

        img {
            width: 30px;
        }
    }
}

.allforoneA,
.allforoneE {
    .texts {
        display: none;
    }
}

@media (min-width:900px) {
    .answer {
        ul {
            font-size: 0.75em;
        }
    }

    .quetions {
        h5 {
            font-size: 1em;
        }
    }

    .b2bgptE,
    .b2bgptA {
        h1 {
            font-size: 2em;
        }

        p {
            font-size: 1em;
        }
    }

    .gptTop {
        .services {
            height: 100px;

            h3 {
                font-size: 1em;
            }
        }
    }

    .allforoneE {
        background: #0B0B2F;
        border-radius: 20px;
        max-width: 90%;
        padding: 40px;
        margin: 0 0 0 auto;
        position: relative;
        right: -20px;



        .texts {
            display: block;
            position: absolute;
            padding: 40px;

            h4,
            h1 {
                color: white;
                width: 50%;
            }

            h1 {
                span {
                    background: linear-gradient(101.23deg, #1877F2 44.3%, #3CC8EB 122.5%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;

                }
            }

            h4 {
                display: none;
            }
        }


        .b2bgptE {
            margin: 0 0 0 auto;
            position: relative;
            right: -40px;
        }
    }

    .allforoneA,
    .allforoneE {
        overflow: hidden;

        .ball1 {
            position: absolute;
            width: 400px;
            height: 312px;
            background: #9C1FC8;
            filter: blur(150px);
            bottom: -100px;

        }

        .ball2 {
            position: absolute;
            width: 500px;
            height: 512px;
            background: #1F58C8;
            left: 50%;
            transform: translate(-50%, -80%);
            filter: blur(217px);
        }
    }

    .allforoneA {
        background: #0B0B2F;
        border-radius: 20px;
        max-width: 90%;
        padding: 40px;
        margin: 0 auto 0 0;
        position: relative;
        left: -20px;

        .b2bgptA {
            margin: 0 auto 0 0;
            position: relative;
            left: -40px;
        }

        .texts {
            display: block;
            position: absolute;
            padding: 40px;

            h4,
            h1 {
                color: white;
                width: 50%;
            }

            h1 {
                font-weight: bold;

                span {
                    background: linear-gradient(101.23deg, #1877F2 44.3%, #3CC8EB 122.5%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                }
            }

            h4 {
                display: none;
            }
        }
    }
}


@media (min-width:1200px) {
    .gptTop {
        .services {
            h3 {
                font-size: 1.2em;

                &:nth-child(1) {
                    top: 40px;
                }

                &:nth-child(3) {
                    top: 20px;
                }
            }
        }
    }

    .allforoneA,
    .allforoneE {
        .texts {
            h4 {
                display: block;
                font-size: 1em;
                margin-top: 30px;
                line-height: 30px;
            }
        }
    }
}

@media (min-width:1500px) {


    .allforoneA,
    .allforoneE {
        .texts {
            h4 {
                display: block;
                font-size: 1.5em;
                margin-top: 30px;
                line-height: 30px;
            }
        }
    }
}