.card_detials {
  p {
    color: #ffff;
    font-size: 16px;
    margin-bottom: 3px;
    font-family: 'Poppins';
  }
}
.cards {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.mobile_Links{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .mobile_links_container{
    display: flex;
    margin: 10px 10px;
    gap: 20px;
    background-color: white;
    border-radius: 30px;
    border: 1px solid rgba(45, 135, 203, 0.751);
    cursor: pointer;
    padding: 5px 20px;
    // width: 150px;
    align-items: center;
    img{
      object-fit: cover;
      height: 25px;
      width: 25px;
      align-self: center;
    }
    p{
      margin: 0;
      color: black;
      font-size: 0.65rem;
    }
    h6{
      margin: 0;
      font-weight: 600;

    }
  }
}

@media screen and (max-width: 550px) {
    .card_detials {
        p{
            font-size: 13px !important;
        }

    }
  }
