.bannerBg {
  padding-top: 50px;
  margin: auto;
  max-width: 1258px;
  text-align: center;

  h1 {
    text-align: left;
    font-size: 2em;
    padding: 20px;
    color: white;
    font-weight: bold;
  }

  h2 {
    color: white;
    text-align: center;
    font-size: 0.8em;
  }

  img {
    &:nth-child(3) {
      width: 90%;
      display: block;
      position: relative;
      margin: 20px auto;
    }

    &:nth-child(2) {
      display: none;
    }
  }

  .overlay {
    background: linear-gradient(
      89.91deg,
      #030314 0.04%,
      rgba(3, 3, 20, 0.281553) 22.29%,
      rgba(3, 3, 20, 0) 53.61%,
      rgba(3, 3, 20, 0.337079) 81.37%,
      #030314 99.9%
    );
  }

  .icons {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;

    > div {
      background: white;
      padding: 8px;
      border-radius: 50%;
      display: flex;
    }
  }
}

.banner {
  padding: 20px;
  position: relative;
}

.b_container {
  display: flex;
  gap: 1.5rem;

  h2 {
    font-size: 1.5rem;
    font-weight: bold;
  }

  h6 {
    color: #777777;
    font-size: 0.65rem;
    font-weight: bold;
  }

  a {
    text-decoration: underline;
    font-size: 0.8rem;
    font-weight: bold;
  }

  img {
    width: 20px;
  }
}

.container {
  position: relative;
  padding: 20px;
  border-top-right-radius: 40px;
  border-top-left-radius: 40px;
  background-color: white;
}

.grid {
  h1 {
    padding: 2rem 0;
    // font-weight: bold;
    // font-size: 2rem;
  }

  h4 {
    font-weight: bold;
    font-size: 1rem;
  }

  hr {
    margin: 0;
    width: 25%;
    margin-bottom: 10px;
    background-color: #030314;
    opacity: 1;
  }
}

.right {
  display: flex;
  padding: 20px;
  justify-content: center;
}

.columns {
  // flex: 1;
  background-color: rgb(223, 105, 211);
  border-top-right-radius: 149.5px;
  border-top-left-radius: 149.5px;
  max-width: 150px;
  height: 278px;

  .imgE {
    width: 260px;
    position: relative;
    display: block;
    margin: auto;
    left: -50px;
    top: 15px;
    z-index: 1;
  }

  .imgA {
    width: 260px;
    position: relative;
    display: block;
    margin: auto;
    top: 15px;
    left: 50px;
    z-index: 1;
  }

  div {
    margin-right: 0;
    height: 150px;
    width: 150px;
    background-color: white;
    position: relative;
    top: -35px;
    border-radius: 50%;
    z-index: 2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h6,
    h1 {
      font-weight: bold;
      display: block;
    }

    h6 {
      line-height: 1rem;
      font-size: 0.8rem;
    }

    h1 {
      font-size: 2rem;
    }
  }
}

.columnsR {
  .grow {
    background-color: #2ed9ff;
    border-radius: 46%;
    height: 150px;
    width: 150px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h6,
    h1 {
      font-weight: bold;
      display: block;
    }

    h6 {
      line-height: 1rem;
      font-size: 0.8rem;
    }

    h1 {
      font-size: 2rem;
    }
  }

  .mobile {
    border-radius: 159px;
    height: 208px;
    width: 150px;
    background-color: #0085e6;
    overflow: hidden;

    .mobileE {
      width: 130px;
      position: relative;
      display: block;
      top: 18px;
      margin: auto;
    }
    .mobileA {
      width: 130px;
      position: relative;
      display: block;
      top: 18px;
      margin: auto;
    }
  }
}

.scaleContainer {
  text-align: center;
  width: 95%;
  margin: 2px auto 40px;
  padding: 40px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.097);
  border-radius: 20px;
  max-width: 1258px;

  h1 {
    font-size: 1.5em;
    // font-weight: bold;
  }

  h6 {
    margin: 20px 0;
    color: #616161;
    font-weight: 600;
    font-size: 0.68em;
  }

  .boxes {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 5px;

    > div {
      box-shadow: 0px 0px 34px rgba(0, 0, 0, 0.12);
      border-radius: 15px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: space-around;
      width: 140px;
      height: 100px;

      img {
        width: 20px;
      }

      h1 {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 1.3em;
        font-weight: 600;
      }

      h6 {
        padding: 0;
        font-size: 0.56em;
        margin: 0;
        font-weight: bold;
      }
    }
  }
}
@media screen and (max-width: 600px) {
  .boxes {
    > div {
      padding: 22px !important;
      width: 150px !important;
      height: 120px !important;
    }
    
  }
  .scaleContainer{
    h6{
font-weight: 300 !important;
    }
  }
}

.contactUs {
  background: linear-gradient(
    270deg,
    #f7f7f7 20.53%,
    rgba(217, 217, 217, 0) 108.24%
  );
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.097);
  width: 95%;
  margin: 50px auto;
  border-radius: 20px;
  padding: 20px;

  h2 {
    font-size: 2em;
  }

  .collaborate {
    margin: 20px auto;

    > div {
      h1 {
        font-size: 2em;
        text-align: center;

        span {
          background: linear-gradient(
            280.33deg,
            #1877f2 27.15%,
            #3cc8eb 92.59%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
        }
      }

      h6 {
        font-size: 0.8em;
        text-align: center;
        padding: 20px 0;
        font-weight: 600;
      }
    }

    h5 {
      font-size: 0.7em;
      text-align: end;
      font-weight: 500;
    }
  }

  .group {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    > label {
      font-size: 0.9em;
      padding: 10px 0 0 0;
      font-weight: 600;
    }

    h2 {
      color: #3cc8eb;
    }
  }
}

.forBusinees {
  width: 90%;
  margin: 60px auto;
  max-width: 1258px;
  text-align: center;

  h1 {
    font-size: 1.5em;
    font-weight: 700;

    span {
      font-weight: 700;
      background: linear-gradient(101.23deg, #1877f2 44.3%, #3cc8eb 122.5%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  h4 {
    padding: 10px 0;
    font-weight: 500;
    font-size: 1em;
    width: 85%;
    margin: auto;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 40px 0;

    > div {
      text-align: center;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.097);
      border-radius: 20px;
      padding: 20px;
      width: 250px;

      img {
        width: 40px;
        margin: 10px 0;
      }

      h4 {
        font-size: 1.2em;
      }

      p {
        color: black;
        font-size: 0.6em;
      }

      a {
        font-size: 0.8em;
        color: #315481;
      }
    }
  }
}

.AiBusiness {
  width: 90%;
  margin: 30px auto;
  max-width: 1258px;

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin: 40px 0;

    > div {
      background: #f3f5f8;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
      border-radius: 20px;
      padding: 20px;
      width: 300px;

      img {
        width: 100%;
        margin: 10px 0;
      }

      h4 {
        font-size: 1.2em;
        font-weight: 600;
        text-align: start;
      }

      p {
        color: black;
        font-size: 0.6em;
        text-align: start;
        list-style: disc;
        display: list-item;
      }

      a {
        font-size: 0.7em;
        color: #315481;
      }
    }
  }
}

@media (min-width: 599px) {
  .boxes #scaleContainer {
    padding: 10px !important;
  }
  .bannerBg {
    h1 {
      padding-top: 30px;
      font-size: 3em;
    }

    .imagesContainer {
      margin-left: 30px;

      h2 {
        text-align: start;
        font-size: 1.3em;
      }

      .icons {
        padding: 10px 0;
        justify-content: flex-start;
      }
    }
  }

  .scaleContainer {
    h1 {
      font-size: 1.5em;
    }

    h6 {
      margin: 20px auto 40px auto;
      font-size: 1em;
      width: 80%;
    }

    .boxes {
      gap: 20px;

      > div {
        width: 180px;
        padding: 30px;

        img {
          width: 30px;
        }

        h1 {
          font-size: 2em;
        }

        h6 {
          font-size: 0.8em;
        }
      }
    }
  }

  .contactUs {
    padding: 30px;

    .group {
      h2 {
        align-self: flex-start;
      }

      > div {
        h1 {
          font-size: 2.5em;
        }

        h6 {
          font-size: 0.8em;
          width: 80%;
          margin: auto;
        }
      }
    }
  }
}

@media (min-width: 900px) {
  .banner {
    // height: 100vh;
    .ball1 {
      position: absolute;
      width: 520px;
      height: 383px;
      left: -190px;
      top: 158px;

      background: rgba(0, 41, 255, 0.3);
      mix-blend-mode: normal;
      filter: blur(137.5px);
    }

    .ball2 {
      position: absolute;
      width: 377px;
      height: 377px;
      right: -220px;
      top: 458px;

      background: rgba(143, 0, 255, 0.6);
      mix-blend-mode: normal;
      filter: blur(137.5px);
    }
  }

  .bannerBg {
    .imagesContainer {
      position: absolute;
      bottom: 10px;
    }
  }

  .bannerBg {
    .imagesContainer {
      .icons {
        > div {
          padding: 10px;

          img {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }

  .bannerBg {
    padding-top: 100px;

    h1 {
      font-size: 3em;
    }

    img {
      &:nth-child(3) {
        display: none;
      }

      &:nth-child(2) {
        width: 90%;
        margin: auto;
        display: block;
        position: relative;
        top: -50px;
      }
    }
  }

  .contactUs {
    border-radius: 50px;
    display: flex;
    gap: 20px;
    padding: 40px;
    position: relative;

    .collaborate {
      flex: 1;

      > div {
        h1 {
          text-align: start;
          font-size: 3em;
        }

        h6 {
          text-align: start;
          font-size: 1em;
          margin: 0;
          width: 100%;
        }
      }

      h5 {
        position: absolute;
        bottom: 50px;
        text-align: start;
        font-size: 1em;
        align-self: baseline;
      }
    }

    .group {
      padding: 40px;
      flex: 1;

      button {
        width: 60%;
      }
    }
  }

  .right {
    justify-content: end;
  }

  .columnsR {
    .grow {
      height: 180px;
      width: 180px;

      h1 {
        font-size: 4em;
      }
    }

    .mobile {
      height: 250px;
      width: 180px;

      .mobileE {
        top: 0px;
        width: 180px;
      }
    }
  }

  .columns {
    height: 360px;
    max-width: 180px;

    div {
      width: 180px;
      height: 180px;
      top: -75px;
    }

    .imgE {
      top: 0;
      width: 380px;
      left: -110px;
    }

    .imgA {
      top: 0;
      width: 380px;
      left: 110px;
    }
  }

  .b_container {
    h2 {
      font-size: 2rem;
    }

    h6 {
      font-size: 0.9rem;
    }

    a {
      font-size: 1.4rem;
    }
  }

  .grid {
    h1 {
      // font-size: 3rem;
    }

    h4 {
      font-size: 1.3rem;
    }
  }

  .scaleContainer {
    border-radius: 50px;
    padding: 80px;

    h1 {
      font-size: 2.5em;
    }

    h6 {
      margin: 30px auto 60px auto;
      font-size: 1.5em;
      width: 80%;
    }

    .boxes {
      gap: 40px;

      > div {
        height: 160px;
        width: 250px;
        padding: 40px;
        border-radius: 20px;

        img {
          width: 50px;
        }

        h1 {
          gap: 20px;
          font-size: 3em;
        }

        h6 {
          font-size: 0.9em;
        }
      }
    }
  }
}

@media (min-width: 1215px) {
  .AiBusiness {
    margin: 50px auto;

    > div {
      justify-content: space-between;

      > div {
        padding: 30px;
        width: 350px;

        h4 {
          font-size: 1.8em;
          letter-spacing: 0.05em;
        }

        p {
          font-size: 1em;
        }

        a {
          font-size: 0.9em;
          color: #315481;
        }
      }
    }
  }

  .forBusinees {
    h1 {
      font-size: 3em;
    }

    > div {
      justify-content: space-between;

      > div {
        padding: 30px;
        width: 350px;

        img {
          width: 70px;
          margin: 10px 0;
        }

        h4 {
          font-size: 1.8em;
        }

        p {
          font-size: 0.8em;
        }

        a {
          font-size: 0.8em;
          color: #315481;
        }
      }
    }
  }

  .contactUs {
    max-width: 1258px;

    .collaborate {
      > div {
        h1 {
          font-size: 5em;
        }
      }
    }

    .group {
      h2 {
        font-size: 4em;
        text-align: left !important;
      }
    }
  }

  .container {
    padding: 60px 0;
    position: relative;
    z-index: 2;
  }

  .columns {
    max-width: 180px;
    height: 360px;

    img {
      width: 400px;
      left: -120px;
    }

    div {
      height: 180px;
      width: 180px;
      top: -80px;

      h1 {
        font-size: 3em;
      }

      h6 {
        font-size: 1.1em;
      }
    }
  }

  .columnsR {
    .grow {
      height: 180px;
      width: 180px;
    }

    .mobile {
      height: 265px;
      width: 180px;

      .mobileE {
        position: relative;
        width: 200px;
        top: -20px;
        left: -10px;
      }

      .mobileA {
        position: relative;
        width: 200px;
        top: 20px;
        left: 10px;
      }
    }
  }

  .scaleContainer {
    padding: 80px;

    h1 {
      font-weight: bold;
      font-size: 3em;
    }

    h6 {
      margin: 40px auto 80px auto;
      font-size: 1.6em;
      width: 90%;
    }

    .boxes {
      gap: 40px;

      > div {
        width: 250px;
        padding: 40px;
        border-radius: 20px;

        img {
          width: 50px;
        }

        h1 {
          gap: 20px;
          font-size: 3em;
        }

        h6 {
          font-size: 0.9em;
        }
      }
    }
  }
}
