h2.twitterheadertitle {
    font-size: 2.5rem !important;
    font-weight: 500;
}

.tweet-section {
    display: flex;
    flex-direction: column;
    min-height: 400px;
    /* justify-content: center; */
    gap: 40px;
    align-items: center;
    text-align: center;
    position: relative;
    padding: 80px 0px 20px 0px;
}

.tweet-image {
    width: 200px;
    height: 200px;
    position: absolute;
    bottom: -92px;
    right: 22px;
}
.tweet-article {
    width: 90%;
}
.tweet-whole-effect {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.tweet-input {
    width: 70%;
    margin: 10px;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
    min-height: 175px;
    padding: 10px;
    max-height: 500px;
    background: white;
    border-radius: 25px;
    box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.151);
    position: relative;
    z-index: 100;
}

.tweet-input input,
textarea {
    border: none;
    width: 100%;
    min-height: 100px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.tweet-generated {
    border: none;
    border-bottom: 1px solid rgb(225, 225, 225);
    width: 100%;
    border-radius: 10px;
    margin: 15px 15px 15px 15px;
    box-shadow: 0 0 15px #f3f3f3;
    padding: 10px 10px 10px 10px;
    overflow: scroll;
}

.tweet-generated,
h6 {
    color: black;
}

.tweet-article-arrow {
    width: 30%;
    position: absolute;
    left: 0%;
    transform: rotate(340deg);
    top: 0;
}

.twitter-icons {
    height: 20px;
    bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    right: 30px;
}
.twitter-icons img {
    height: 20px;
    width: 20px;
}

.tweet-btn {
    border: none;
    height: 40px;
    width: 100px;
    background-color: #35B1EA;
    border-radius: 25px;
    color: white;
}
.tweet_upper {
    display: flex;
    align-items: center;
    width: 100%;
}
.tweet_lower {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tweet-btn-grey {
    border: none;
    height: 40px;
    width: 100px;
    background-color: grey;
    border-radius: 25px;
    color: white;
}

.tweet-profle-none {
    display: none;
}

@media (min-width: 991px) {
    .log-in-out-btn{
        display: block !important;
    }
}

@media (max-width:1600px) {
    .h1blog,   .h1eh {
        font-size: 30px !important;
    }
  
}


@media only screen and (max-width: 1050px) {
    .tweet-input {
        width: 100%;
        min-height: 120px;
        max-height: none;

    }
  
    .tweet-image {
        width: 96px;
        height: 96px;
        bottom: -72px;
        right: 0px;
    }

    .tweet-profile {
        height: 55px;
        width: 55px;
    }

    .twitter-icons {
        height: 15px;
        right: 10%;
        bottom: 25px;

    }

    .tweet-btn {
        width: 80px;
        height: 30px;
        bottom: 15px;
        left: 30px;
    }

    .tweet-btn-grey {
        width: 80px;
        height: 30px;
        bottom: 15px;
        background-color: grey;
        color: white;
    }
}

@media only screen and (max-width: 800px) {
    .tweet-input {
        width: 90%;
        min-height: 120px;
        max-height: none;

    }

    .tweet-input input {
        min-height: 60px;
    }

    /* 
    .tweet-input input,
    textarea {
        border: none;
        border-bottom: 1px solid rgb(225, 225, 225);
        width: 100%;
        border-radius: 10px;
        height: 100%;
        margin: 15px 15% 15px 15px;
        box-shadow: 0 0 15px #f3f3f3;
        padding-bottom: 60px;
        padding-left: 10px;
        padding-top: 10px;
    } */

    .tweet-image {
        display: none;
    }

    .tweet-border {
        display: flex;
        flex-direction: column-reverse;
        /* padding-left: 0%; */
        width: 100%;
        border-radius: 0%;
        min-height: 400px;
        margin-bottom: 40px;
    }
    .tweet-border-ar {
        display: flex;
        flex-direction: column-reverse;
        /* padding-left: 0%; */
        width: 100%;
        border-radius: 0%;
        min-height: 400px;
        margin-bottom: 40px;
    }

    .tweet-profile {
        height: 60px;
        width: 60px;
    }

    .twitter-icons {
        height: 17.5px;
        right: 15%;
        bottom: 20%;
    }

    .tweet-btn {
        width: 80px;
        height: 30px;
        bottom: 12.5%;
        left: 20px;
    }

    .tweet-btn-grey {
        width: 80px;
        height: 30px;
        background-color: grey;
        color: white;
    }

}


@media (max-width:991px) {
    .glow-on-hover{
        width:  140px !important; 
        height: 40px !important;
    }
} 


@media (max-width:600px) {
    .login-button-icon {
        width: 15px !important;
    }

    .glow-on-hover {
        width: 130px !important;
        height: 35px !important;
    }

    .dash-btn,
    .log-in-out-btn {
        font-size: 10px !important;
    }

    /* .log-in-out-btn {
        display: none;
    } */
}

@media (max-width:400px) {
    .glow-on-hover {
        width:110px !important;
        height: 30px !important ;
    }
    /* .dash-btn ,.log-in-out-btn
        {
        font-size: 10px !important;
    } */
    /* .log-in-out-btn{
        display: none;
    } */
}

@media (max-width:445px) {
    .log-in-out-btn{
        font-size: 12px;
    }
}


.log-in-out-btn,.dash-btn{
    font-size: 15px;
    font-weight: 700;
}

.glow-on-hover {
    width: 180px;
    height:45px;
    border: none;
    outline: none;
    color: #fff;
    background: #212224;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #8fc3f3, #3a95f6, #7a14ff, #8fc3f3, #3a95f6, #7a00ff, #8fc3f3, #3a95f6,#c7a1ff);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 30px;
}

.glow-on-hover:active {
    color: #000
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:before {
    opacity: 1;
}

.glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #212224;
    left: 0;
    top: 0;
    border-radius: 30px;
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.login-button-icon{
    margin-left: 2px;
}