/* .App {
    display: flex;
    align-items: center;
    flex-direction: column;
} */
.bannerSectionWrapper {
    width: 100%;
}
/* 
.w100Width {
    max-width: 100%;
}
  .w1200Width {
    max-width: 1400px;
}
.creative_writing_section {
    width: 100%;
}
.MuiGrid-container {
    max-width: 1400px;
} */
.blogs_mobil{
    display: none;
}

@media  screen and (max-width : 700px) {
    .blogs_mobil{
        display: block;
    }
    .blog_dash{
        display: none;
    }
    .blogs_mobil .prev{
        left: 5px !important;
    }
    .blogs_mobil .next{
        right: 8px !important;
    }
}


