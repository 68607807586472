 .appBg {
   background-image: url(../../public/assets/gradient-backdrop.png);
   min-height: 575px;
   width: 100%;
   /* overflow: hidden; */
   background-repeat: no-repeat;
   background-size: cover;
   position: relative;
   margin-top: 88px;
 }
 .privacyWrapper {
  background-image: url(../../public/assets/gradient-backdrop.png);
   height: 100vh;
   width: 100%;
   /* overflow: hidden; */
   background-repeat: no-repeat;
   background-size: cover;
   position: fixed;
   top: 0px;
   right: 0px;
   left: 0px;
   bottom: 0px
   /* overflow: hidden; */
 }
 .generateBg {
   background-image: url(../../public/assets/generate-section.svg);
   height: 100%;
   width: 100%;
   /* overflow: hidden; */
   background-repeat: no-repeat;
   /* background-size: contain; */
   background-size: 100% auto;
   background-position: center;

 }

 .container {
   margin: 0 2rem;
 }

 .main {
   min-height: 100vh;
   padding: 4rem 0;
   flex: 1;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
 }

 .footer {
   display: flex;
   flex: 1;
   padding: 2rem 0;
   border-top: 1px solid #eaeaea;
   justify-content: center;
   align-items: center;
 }

 .footer a {
   display: flex;
   justify-content: center;
   align-items: center;
   flex-grow: 1;
 }

 .title a {
   color: #0070f3;
   text-decoration: none;
 }

 .title a:hover,
 .title a:focus,
 .title a:active {
   text-decoration: underline;
 }

 .title {
   margin: 0;
   line-height: 1.15;
   font-size: 4rem;
 }

 .title,
 .description {
   text-align: center;
 }

 .description {
   margin: 4rem 0;
   line-height: 1.5;
   font-size: 1.5rem;
 }

 .code {
   background: #fafafa;
   border-radius: 5px;
   padding: 0.75rem;
   font-size: 1.1rem;
   font-family: Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono,
     Bitstream Vera Sans Mono, Courier New, monospace;
 }

 .grid {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-wrap: wrap;
   max-width: 800px;
 }

 .card {
   margin: 1rem;
   padding: 1.5rem;
   text-align: left;
   color: inherit;
   text-decoration: none;
   border: 1px solid #eaeaea;
   border-radius: 10px;
   transition: color 0.15s ease, border-color 0.15s ease;
   max-width: 300px;
 }

 .card:hover,
 .card:focus,
 .card:active {
   color: #0070f3;
   border-color: #0070f3;
 }

 .card h2 {
   margin: 0 0 1rem 0;
   font-size: 1.5rem;
 }

 .card p {
   margin: 0;
   font-size: 1.25rem;
   line-height: 1.5;
 }

 .logo {
   height: 1em;
   margin-left: 0.5rem;
 }

 @media (max-width: 600px) {
   .grid {
     width: 100%;
     flex-direction: column;
   }
 }

 @media (prefers-color-scheme: dark) {

   .card,
   .footer {
     border-color: #222;
   }

   .code {
     background: #111;
   }

   .logo img {
     filter: invert(1);
   }
 }

 @media (min-width: 1750px) {
   .appBg {
     background-size: cover;
   }

 }

 @media(max-width:780px) {
   .generateBg {
     background-size: auto auto;

   }
 }

 .parallax > use {
  animation: move-forever 25s cubic-bezier(.55,.5,.45,.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 10s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 13s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 16s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 27s;
}
@keyframes move-forever {
  0% {
   transform: translate3d(-90px,0,0);
  }
  100% { 
    transform: translate3d(85px,0,0);
  }
}
/*Shrinking for mobile*/
@media (max-width: 900px) {
  .waves {
    height:7vh;
  }
}

.wavy {
  animation: wave 120s linear infinite
}
