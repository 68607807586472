$title-font-size: clamp(1.5em, 3.5vw, 3em);
.wrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 700px;

  .title-section {
    display: flex;
    align-items: center;
    margin-bottom: 2em;
    .checkicon {
      color: green;
      margin: 0 clamp(0.3em, 2vw, 1em) 0 0;
      margin-bottom: 15px;
      width: $title-font-size;
      height: $title-font-size;
    }
    .title {
      padding: 0;
      margin: 0;
      font-size: $title-font-size;
    }
  }
  .message {
    font-size: clamp(0.8em, 1.5vw, 1.25em);
    margin-bottom: 2em;
  }
}

@media screen and (max-width: 450px) {
  .wrapper {
    .title-section {
      justify-content: center;
    }
  }
}
