.navbar {
  transition: all 0.3s ease-in-out;
}


/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #a90000;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
  }
  
  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  /* Individual item */
.bm-item {
    display: inline-block;
  
    color: #d1d1d1;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    transition: color 0.2s;
  }
  
  .bm-item:hover {
    color: #ffffff;
  }

  .sidebar-menu-icon{
    display: none ;
    margin-right: 20px;
  }


  @media (max-width : 1200px) {
    .sidebar-menu-icon{
      display: flex !important;
    }
  }


.burger-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  cursor: pointer;
  gap: 5px;
}

.burger-bar {
  width: 25px;
  height: 2px;
  background-color:white;
  border-radius: 0.5em;
}

.menu {
  width: 100%;
  height: 100vh;
  background-color:white;
  position: absolute;
  top: 0;
  z-index: -1;
  margin-top: 109px;
  
}

/* ------------- sliding menu ------------------ */
.hidden {
  display: none;
  transform: translateX(0);
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.2s; 
}

.visible {
  justify-content: center;

  display: inline;

}

/* ----- animations--------- */
/* clicked */
.burger-bar.clicked:nth-child(1){
  transform: rotate(45deg) translate(10px,0px);
  transition: ease-out 0.2s;
}

.burger-bar.clicked:nth-child(2){
  /* transform: opacity(0); */
  opacity: 0;
  /* transform: scale(0); */
  transition: ease-out 0.2s;
}

.burger-bar.clicked:nth-child(3){
  transform: rotate(135deg) translate(-10px);
  transition: ease-out 0.2s;
}

/* unclicked */
.burger-bar.unclicked {
  transform: rotate(0) translate(0);
  transition: cubic-bezier(0.175, 0.885, 0.32, 1.275) 0.2s;
}

.araby_ai_name{
  /* display: none; */

  color: white;
   font-weight: 600;
    font-size: 15px;
    position: relative;

    top: 4px;
    left: -8px;
}