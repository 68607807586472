
.main_dynamic_p {
    border-bottom: 4px solid transparent;
    border-image: linear-gradient(90deg, #FFD913 0%, #4E4ADD 100%);
    border-image-slice: 1;
  }
  
  .main_dynamic {
      background: linear-gradient(270deg, #911DC1 0%, #D4307A 100%);
      border-bottom: 4px solid transparent;
      border-image: linear-gradient(90deg, #2AC0FF 0%, #921EC1 100%);
      border-image-slice: 1;
  
      .dynamic_app {
        color: #ffff;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
  
        .logo_dynamic {
          display: flex;
          align-items: center;
          gap: 10px;
          label {
            color: #ffff;
            font-size: 14px !important;
            font-weight: 400;
            margin-bottom:0; 
          }
        }
  
        .get_app {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;
  
          a {
            color: #1c64b1;
            p {
              border: none;
              color: #ffff;
              font-size: 10px;
              margin: 0px;
              font-weight: 300;
            }
          }
        }
      }
    }
  
    .logo_dynamic img {
      background: #161616;
      border: 0.5px solid #FFFFFF;
      border-radius: 5px;
      padding: 5px;
    }

    .lng_dir{
      direction: ltr;
    }
    .lng_dir_ar{
      direction: rtl;
    }