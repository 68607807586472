.wrapper {
  margin: 0 auto;
  width: 450px;
  max-width: 500px;
  .logo {
    width: 40%;
    max-width: 150px;
    margin: 0 auto;
  }

  .description {
    margin-bottom: 2em;
  }

  .error-message {
    color: red;
    font-size: 0.7em;
    font-weight: 500;
  }
  .respone-error-message {
    color: red;
    text-align: center;
  }

  .form {
    width: 90%;
    max-width: 400px;
  }
  .textfield:focus {
    border: 2px solid rgba(60, 200, 235, 1);
    box-shadow: 0 0 0 transparent;
  }

  .textfield-error,
  .textfield-error:focus {
    transition: all 0 ease-in-out;
    border: 1px solid red;
    box-shadow: 0 0 0 transparent;
  }
}

@media only screen and (max-width: 450px) {
  .wrapper {
    overflow-x: auto;
    width: 90%;

    .logo {
      margin: 0 auto;
      display: block;
      width: 30%;
    }
    .form {
      margin: 0 auto;
    }

    .title,
    .description {
      text-align: center;
    }
  }
}
