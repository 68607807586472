.btn.common-btn-modal {
    padding: 15px 60px;
    transform: scale(0.9);
    border-radius: 15px;
    background: rgb(17, 113, 216);
    background: linear-gradient(45deg, rgba(17, 113, 216, 1) 0%, rgba(60, 200, 235, 1) 100%);
    color: #fff;
    font-size: 1.5rem;
    font-weight: 800;
    border: unset;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0px 10px 0px;
}

.btn.common-btn-modal:hover .btn.common-btn-modal:focus {
    /* transform: scale(1); */
    background-color: linear-gradient(45deg, rgba(17, 113, 216, 1) 0%, rgba(60, 200, 235, 1) 100%);
    opacity: 1;
    color: #fff !important;
    border-color: white !important;
    transition: all 0.5s ease;
}

.upgrade-modal .modal-content {
    border-radius: 1rem;
    padding: 20px;
}

.upgrade-modal .modal.show .modal-dialog {
    transform: none !important;
}

@media (max-width: 450px) {
    .upgrade-modal .modal-content {
        min-width: 100%;
    }

    .btn.common-btn-modal {
        padding: 15px 60px;
        transform: scale(0.9);
        border-radius: 15px;
        background: rgb(17, 113, 216);
        background: linear-gradient(45deg, rgba(17, 113, 216, 1) 0%, rgba(60, 200, 235, 1) 100%);
        color: #fff;
        font-size: 1rem;
        font-weight: 800;
        border: unset;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0px 10px 0px;
    }
}