@media (max-width: 400px) {
     .btn.auth-btn  {
        padding: 7px 15px !important; 
        font-size: 12px !important;
    }
    .login-button-icon{
        width: 10px !important;
    }
}
.login-button-icon{
    width: 20px;
}

@media (min-width:440px) {
    .header {
        min-height: 70px;
    }
}
@media (min-width:728px) {
    /* .header {
        padding: 5px;
    } */
    .logo{
        width: 55px ;
        height: 55px;
    }
}

@media (min-width:991px) and (max-width:1268px) {
    .leftSideHeader{
        gap: 50px;
        left: 0px;
    }
    
}

@media (min-width:991px) {
    .araby_ai_name{
        font-size: 30px !important;
        left: -12px !important;
    }
    /* .header {
        padding: 10px;
    } */
    .logo{
        width: 60px ;
        height: 60px;
    }
}

@media (min-width:1200px) {
    .header {
        padding: 20px;
    }
}
.header {
    padding: 10px 10px 10px 0;
}

@media (max-width: 440px) {
    .logoutBTN {
        display: none;
    }
    .header {
        min-height: 60px;
    }
    .auth-text-btn {
        border-radius: 8px;
        background-color: none;
        color: black;
        padding: 7px 22px 7px 22px;
        font-size: 9px;
        font-weight: 500;
        margin-right: 10px;
        color: white;
    }

    .btn.auth-btn {
        border-radius: 8px;
  background: linear-gradient(60deg,#3e98f8 0%, #7a14ff 100%);  
  border-radius: 100px;
  color: #fff;
  padding: 7px 22px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

    }
}
@media (max-width: 601px) {
    .footerElement {
        width: 50%;
    }
    .fs-5 {
        font-size: 12px !important;
    }
    /* .logo {
        height: 40px;
        width: 36px;
    } */
    .logo_header_wrapper {
        padding-left: 0px !important;
    }
    .ai-banner-section{
        padding-right: 0% ;
    }
    .h1header{
        font-size: 30px;
    }
    .Home_appBg__7itno {
        margin-top: 0px !important;
    }
    .headerIcon {
        width: 25px !important
    }
    .btns_wrapper {
        gap: 10px !important;
    }
}
.headerIcon {
    width: 35px;
}
@media (max-width: 325px) {
    .fs-5 {
        font-size: 10px !important;
    }
}
@media (max-width: 1082px) {
    .white_paper {
        width: 90%;
        margin-top: 60px;
        padding: 20px;
    }
    .count_wrapper {
        justify-content: space-evenly;
    }
}

@media (max-width: 715px) {
    /* .header{
        padding: 10px 0px 10px 0px;
        right: -53px !important;

    } */
    
}
