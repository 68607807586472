.navbar {
  margin-right: 25px;
  display: flex;
  flex-wrap: nowrap;
  position: sticky;
  top: -1px;
  width: 100%;
  z-index: 999;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.navbar-hide {
  transform: translateY(-100%);
  opacity: 0;
}

.navbar-show {
  transform: translateY(0);
  opacity: 1;
}
.n-nav {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  
  .n-background {
    position: absolute;
    top: 0px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    margin: auto;
    /* background: #fff; */
  }
  .bg-bb{
    width: 100vw;
    height: 100vh;
    background-color: black;
    position: absolute;
    z-index: 1;
  }
  .n-button {
    outline: none;
    border: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor: pointer;
    position: absolute;
    top: 18px;
    left: 8px;
    border-radius: 50%;
    background-color: transparent;
  }
  
  .n-ul,
  .n-li {
    margin: 0;
    padding: 0;
  }
  
  .n-ul {
    padding: 25px;
    position: absolute;
    top: 60px;
    width: 100%;
    margin: auto;
    background-color: black;
  }
  
  .n-li {
    padding: 10px;
    list-style: none;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    z-index: 3;
  }

  
  .n-icon-placeholder {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex: 40px 0;
    margin-right: 20px;
  }
  
  .n-text-placeholder {
    border-radius: 5px;
    width: 200px;
    height: 20px;
    flex: 1;
  }
  .button-login{
    margin: auto;
    display: flex;
  }

 
  
  .refresh {
    padding: 10px;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    width: 20px;
    height: 20px;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  @media (min-width:991px) {
    .n-button{
      display: none;
    }
    
  }


  @media (min-width:440px) {

      .n-button {
        top: 24px;
      }
  }
  @media (min-width:600px) {
    .button-to-display{
      display: none;
    }
  }

  
/* 
  .comingSoon{
    background: linear-gradient(180deg, rgba(75,219,255,1) 0%, rgba(0,153,255,1) 67%);
    height: 100svh;
    height: 100vh;
  }
  .valign {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
} */

/* h1,h6{
  text-align: center;
  padding: 0 2em;
}

h1{
  font-size: clamp(22px, 12vw ,48px) !important;
  color: white;
}

h6{
  color:white;
  font-size: clamp(14px, 8vw ,28px) !important;
} */