.wrapper {
  width: 400px;
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  // align-items: center;
  gap: 10px;
  text-align: center;
  justify-content: center;
  .logo {
    width: 40%;
    max-width: 150px;
    margin: 0 auto;
  }
  .otp-input-wrapper {
    display: flex;
    margin: 1em 0;
    justify-content: center;
    .otp-input {
      input {
        padding: 0 !important;
        font-size: 2.25em;
        width: 2.8rem !important;
        margin: 0.1em;
        border: 1px solid rgb(175, 175, 175);
        border-radius: 10%;

        &:focus {
          border: 2px solid rgba(60, 200, 235, 1);
        }
      }
    }
  }

  .error-message {
    color: red;
    font-size: 0.7em;
    font-weight: 500;
  }
  .respone-error-message {
    color: red;
    text-align: center;
  }
}

@media only screen and (max-width: 450px) {
  .wrapper {
    width: 90%;
    overflow-x: auto;

    .logo {
      margin: 0 auto;
      display: block;
      width: 30%;
    }

    .title,
    .description {
      text-align: center;
    }
  }
}
