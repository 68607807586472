.logo_header_wrapper{
    h4{
        color: white;
    }
}

.dropdown{
    border-radius: 20px;    
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: black;
    cursor: pointer;
    img{
        width: 20px;
    }
    img:nth-of-type(1){
        border: 2px solid white;
        border-radius: 50%;
    }
    h5{
        color: white;
        margin: 0;
        font-size: 18px;
        font-weight: 500;
        // font-family: "Tajawal" !important; 
    }
}


@media (min-width:715px) {
  .dropdown{
    padding: 9px;
  }
  .language{
    margin-top: -38px !important;
  }
}

@media (max-width:"715px") {
    .araby {
      display: none !important;
    }
  }

@media (max-width:"580px") {
    .araby {
      display: none !important;
    }
  } 

  .language{
      position: relative;
      margin-top: -28px;
      margin-right: 100px;
  }