.details {
    display: flex;
    flex-direction: column;
    width: 100%;

    .banner {
        display: flex;
        flex-direction: column;
        background-color: #030314;
        width: 100%;
        position: relative;
        background-image: url(../../../public/assets/images/blog/banner.png);
        background-repeat: no-repeat;
        background-size: cover;
        height: 630px;

        >div:first-child {
            padding: 8%;

            p:first-child {
                text-align: center;
                font-size: 55px;
                font-weight: 600;
                line-height: 117%;
                color: #FFFFFF;
                margin: 0;
            }
        }
    }

    .blogDetailImage {
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 1;
        
        img {
            border-radius:5px;
            object-fit: contain;
            width: 80%;
            // height: 437px;
            margin-top: -230px;
        }
    }

    .blogContent {
        padding: 4% 10%;
        .blogDetailContent {
            
        }
    }

    .blogDetailHeading {
        color: #000000;
        font-weight: 700;
        line-height: 160%;
        font-size: 25px;
        margin-top: 36px;
        margin-bottom: 10px;
    }

    .blogDetailParagraph {
        color: #000000;
        font-weight: 400;
        line-height: 150%;
        font-size: 18px;
        margin-bottom: 0;
    }

}

@media screen and (max-width: 991.98px) {
    .details {
        .banner {
            >div:first-child {
                padding: 20%;

                p:first-child {
                    font-size: 32px;
                }
            }
        }

        .blogDetailImage {
            img {
                // width: 500px;
                // height: 437px;
                margin-top: -160px;
            }
        }

        .blogDetailHeading {
            font-size: 22px;
            // margin-top: 36px;
            // margin-bottom: 16px;
        }
    
        .blogDetailParagraph {
            font-size: 15px;
        }

    }
}

@media screen and (max-width: 728px) {
    .details {
        .banner {
            height: unset;
        }
        .blogDetailImage {
            display: none;
        }
    }

    .blogContent {
        padding: 4% !important;
    }
}