@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800&display=swap");

:root {
  --font-family-body: "Tajawal", sans-serif;
  --purple-blue-color: rgb(110, 99, 250);
  --font-family-heading: "Tajawal", sans-serif;
  --text-color: #02001d;
  /* --text-color: rgb(1, 16, 50); */
  --common-button-color: rgb(122, 20, 255);
  --grey: rgb(104, 114, 125);
}
.password_options {
  padding: 5px;
  margin-top: 10px;
  overflow: hidden;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  height: 20px;
  transition: height 1s ease-out;
}
.Toastify,
.Toastify__toast {
  font-family: 'Tajawal' !important;
}

@media (max-width:1599px) {
  .statistics_page {
    max-width: 950px !important;
  }
}

.statistics_page {
  max-width: 1250px;
  top: -80px;
}

html,
body {
  font-family: var(--font-family-body);
  font-weight: normal;
  font-size: 16px;
  font-style: normal;
  padding: 0;
  margin: 0;
  overflow-x: hidden;
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
}

a {
  color: inherit;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

html {
  color-scheme: light;
}

body {
  color: rgb(0, 0, 0);
  background: rgb(255, 255, 255);
}

h1 {
  font-size: 2.5rem;
}

.logo {
  /* height: 97px; */
  height: 40px;
  /* width: 108px !important; */
  width: 40px ;
}

h1 {
  color: var(--text-color);
  font-weight: 500;
}

h6 {
  color: var(--grey);
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
}
.twitter-subheader, .blog-subtext {
  font-weight: 300;
}
p {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.6;
  color: rgba(17, 113, 216, 1);

  margin-bottom: 20px;
}

ul {
  margin: 0px;
  padding: 0px;
}

li {
  list-style: none;
}

.navbar-wrap {
  display: flex;
  /* flex-grow: 1; */
}

/* .pop_sign_up_form button {
  background-color: transparent;
  color: #1171d8;
} */
.btns_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    right: -70px;
    gap: 20px;
  padding: 5px;
}
.btns_wrapper a,
.btns_wrapper button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 5px;
}

.navbar-wrap ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* flex-wrap: wrap; */
  /* margin-left: auto; */
}

.navbar-wrap ul li {
  position: relative;
}

.navbar-wrap ul li a {
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  position: relative;
  z-index: 1;
}

.navbar-wrap>ul>li.active>a,
.navbar-wrap>ul>li:hover>a {
  color: rgb(1, 16, 50);
}

.navbar-wrap li a {
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
}
.navbar-wrap li a:hover {
  color: #026FAD;
}
.navbar-wrap {
  display: flex;
  align-items: center;
  gap: 50px;
  justify-content: space-evenly;
}

.leftSideHeader {
  display: flex;
  align-items: center;
  gap: 60px;
  position: relative;
  left: 40px;
}

.logoutBTN {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 6px;

  background: linear-gradient(180deg,
      /* #8fc3f3 0%, */
      #3a95f6 20%,
      #7a14ff 90%
      /* #c7a1ff */
    );
}

.newDesignBTN {
  color: #fff;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.newDesignBTN:hover {
  color: rgba(255, 255, 255, 0.836);
}

.or_effect {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.or_effect label {
  font-size: 3rem;
  color: #c7dff7;
  font: 700;
}
.line_effect {
  width: 4px;
  height: 100%;
  background-color: #c7dff7;
}
.creative_writing_section {
  background-color: #F7FBFF;
  justify-content: center;
  padding: 0px 20px 0px 20px;
}
.header {
  direction: ltr;
  background-color: #000;
  display: flex;
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  z-index: 1000;
  flex-direction: row;
  justify-content: space-between;
  transition: all 5s ease-in-out;
  /* padding: 20px 20px 15px 20px; */
}


.start_now_btn_pricing {
  position: relative;
  color: white;
  /* height: 48px;
  width: 150px; */
  border: 1px solid #fff;
  transition: color 0.5s, transform 0.2s, background-color 0.2s;
  outline: none;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

@media screen and (min-width:900px) {

  .start_now_btn_pricing,
  .start_now_btn,
  .start_now_btn_inner,
  .pricing_btn_black {
    border: none;
  }

  .start_now_btn_pricing:active,
  .start_now_btn:active,
  .start_now_btn_inner:active,
  .pricing_btn_black:active {
    transform: translateY(3px)
  }

  .start_now_btn_pricing::after,
  .start_now_btn_pricing::before,
  .start_now_btn::after,
  .start_now_btn::before,
  .start_now_btn_inner::after,
  .start_now_btn_inner::before,
  .pricing_btn_black::after,
  .pricing_btn_black::before {
    border-radius: 100px
  }

  .start_now_btn_pricing:active,
  .start_now_btn:active,
  .start_now_btn_inner:active,
  .pricing_btn_black:active {
    transform: translateY(3px)
  }

  .start_now_btn_pricing::after,
  .start_now_btn_pricing::before,
  .start_now_btn::after,
  .start_now_btn::before,
  .start_now_btn_inner::after,
  .start_now_btn_inner::before,
  .pricing_btn_black::after,
  .pricing_btn_black::before {
    border-radius: 100px
  }

  .start_now_btn_pricing:hover,
  .start_now_btn:hover {
    background: transparent;
    box-shadow: none;
    color: #fff
  }

  .start_now_btn_pricing::before,
  .start_now_btn::before,
  .start_now_btn_inner::before,
  .pricing_btn_black::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.3s, border 0.3s
  }

  .start_now_btn_pricing:hover::before,
  .start_now_btn:hover::before,
  .start_now_btn_inner:hover::before,
  .pricing_btn_black:hover::before {
    opacity: 0
  }

  .start_now_btn_pricing::after,
  .start_now_btn::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    padding: 7px 22px;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 100px;
    opacity: 0;
    z-index: -1;
    transform: scaleX(1.1) scaleY(1.3);
    transition: transform 0.3s, opacity 0.3s
  }

  .pricing_btn_black::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    height: 47px;
    width: 149px;
    background-color: transparent;
    border: 1px solid #026fad;
    border-radius: 30px;
    opacity: 0;
    z-index: -1;
    transform: scaleX(1.1) scaleY(1.3);
    transition: transform 0.3s, opacity 0.3s
  }

  .start_now_btn_inner::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    height: 47px;
    width: 149px;
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 30px;
    opacity: 0;
    z-index: -1;
    transform: scaleX(1.1) scaleY(1.3);
    transition: transform 0.3s, opacity 0.3s
  }

  .start_now_btn_pricing:hover::after,
  .start_now_btn:hover::after,
  .start_now_btn_inner:hover::after,
  .pricing_btn_black:hover::after {
    opacity: 1;
    transform: scaleX(1) scaleY(1)
  }
}

.btn {
  border: none;
  background: none;
}

/* .btn:hover::after {
    opacity: 1;
    transition: all 0.5s ease;
  } */
.common-btn:disabled {
  background: gray !important;
}
.btn.common-btn {
  padding: 15px 60px;
  transform: scale(0.9);
  border-radius: 30px;
  /* background-color: var(--common-button-color); */
  /* background: rgb(17, 113, 216); */
  background: linear-gradient(45deg,
      rgba(17, 113, 216, 1) 0%,
      rgba(60, 200, 235, 1) 100%);
  color: #fff;
  font-size: 1rem;
}

.btn.common-btn:hover {
  transform: scale(1);
  border-radius: 30px;
  background-color: var(--text-color);
  opacity: 1;
  color: #fff;
  transition: all 0.5s ease;
}

.header-btn-active {
  padding: 7px 22px 7px 22px;
  color: rgb(1, 16, 50);
  font-size: 14px;
  font-weight: 500;
}

.header-btn {
  padding: 7px 22px 7px 22px;
  color: var(--grey);
  font-size: 14px;
  font-weight: 500;
}

.btn.auth-btn {
  border-radius: 8px;
  /* background-color: var(--purple-blue-color); */
  background: linear-gradient(90deg,
      /* #8fc3f3 0%, */
      #3a95f6 20%,
      #7a14ff 100%
      /* #c7a1ff */
    );
  /* border: 1px solid white; */
  border-radius: 100px;
  color: #fff;
  padding: 7px 22px;
  font-size: 14px;
  /* font-weight: 500; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.auth-btn:hover {
  color: #fff;
  background: transparent;
  opacity: 1;
  transition: all 0.5s ease;
}

.auth-text-btn {
  border-radius: 8px;
  background-color: none;
  color: black;
  padding: 7px 22px 7px 22px;
  font-size: 14px;
  font-weight: 500;
  margin-right: 10px;
  color: white;
}

.fix {
  font-weight: 600;
}

.auth-text-btn:hover {
  background-color: var(--purple-blue-color);
  color: #fff;
}

.custom-search {
  position: relative;
  width: 90%;
  flex-direction: row;
  display: flex;
}

.custom-search-input {
  width: 100%;
  /* line-height: 1; */
}

.custom-search-btn {
  position: absolute;
  /* background: rgb(26, 199, 65); */
  color: #fff;
  z-index: 2;
  background: rgb(17, 113, 216);
  background: linear-gradient(47deg,
      rgba(17, 113, 216, 1) 0%,
      rgba(60, 200, 235, 1) 100%);
}
@media only screen and (max-width: 375px) {
  .image_itself{
   display: none !important;
  }
}
@media (min-width:715px) {
  .btns_wrapper{
    right: 0;
  }

}
@media (min-width:600px) {
  .btn.auth-btn {
    padding: 0px;
  }
  
}
@media (max-width:600px) {
  #scaleContainer {
    padding: 10px !important;
  }
  
}

@media only screen and (max-width:991px) {
  .image_itself{
    width: 50% !important;
  }
}

@media only screen and (max-width: 650px) {
  .auth_modal .modal-content{
    border-radius: 20px !important;
  }
  .auth_modal .modal-body{
    padding: 0px 40px!important;
  }
 
  .modal-content{
    width: 90% !important;
    margin: auto !important;
  }

  .fw-links{
    flex-direction: row !important;
    align-items: center !important;
    justify-content: space-between !important;
    flex-wrap: wrap !important;
    width: 65% !important;
  }

  .count_wrapper{
    padding: 20px 0 !important;
  }
  .h1eh, .we-turn-your{
    font-size: 30px !important;
  }
  
  /* .h1blog{
    font-size: 20px !important;
  } */
  .h6introcontent{
    width: 100% !important;
    margin-top: 10px !important;
    font-size: 18px !important;
  }
  .custom-search-input {
    width: 100%;
    font-size: 12px;
    /* line-height: 1; */
  }

  .custom-search-btn {
    position: absolute;
    /* background: rgb(26, 199, 65); */
    color: #fff;
    z-index: 2;
    background: rgb(17, 113, 216);
    background: linear-gradient(47deg,
        rgba(17, 113, 216, 1) 0%,
        rgba(60, 200, 235, 1) 100%);
    font-size: 14px;
  }
}

/* Footer BEGINNING */
.footer-top-wrap {
  background: var(--text-color);
  padding: 30px 0 40px;
}

/* .footer-widget {
  margin-bottom: 40px;
} */

.footer-widget p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.8;
  font-weight: 500;
}

.footer-social {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* margin-top: 23px; */
}

/* .footer-social li:not(:last-child) {
  margin-right: 8px;
} */

/* .footer-social li a:hover {
    background: var(--purple-blue-color);
    color: #fff;
    transition: all 0.3s ease-out 0s;
  } */

.footer-social ul li .icon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0px;
  font-size: 20px;
  background: #fff;
  color: #000;
  z-index: 1;
}


.footer-social ul li .icon:hover::before {
  background: none;
  /* transition: .25s linear; */
  transition: 0.25s ease-in-out;
}

/* 
  .facebook-icon {
    background: url(../public/assets/icons/facebook-icon.png) no-repeat;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

  }

  .facebook-icon:hover {
    background: url(../public/assets/icons/facebook-logo-blue.png) no-repeat;
    background-size: cover;
    background-position: center;
    transition: 0.3s ease-in;
  } */

.footer-social ul li a {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #c8c5c5;
  font-size: 13px;
  font-weight: 600;
}

.footer-social ul li a:hover {
  color: var(--purple-blue-color);
}

/* .footer-social ul li+li {
    margin-top: 13px;
  } */

.footer-social .title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 15px;
}

.fw-title {
  font-size: 20px;
  color: #e6e6e6;
  font-weight: 600;
  margin-bottom: 28px;
}

.fw-links li+li {
  margin-top: 8px;
}

.fw-links li a {
  color: #c8c5c5;
  font-size: 14px;
  font-weight: 500;
}

.fw-links li a:hover {
  /* color: #282a6b; */
  color: var(--purple-blue-color);
  padding-left: 5px;
  transition: all 0.3s ease-out 0s;
}

.fw-links li button {
  color: #c8c5c5;
  font-size: 14px;
  font-weight: 500;
  background: none;
  border: none;
  padding-left: 0px;
  transition: all 0.25s ease-in 0s;

}

.fw-links li button:hover {
  /* color: #282a6b; */
  color: var(--purple-blue-color);
  padding-left: 5px;
  transition: all 0.3s ease-out 0s;
  --webkit-appearance: none;
}

.copyright-wrap {
  /* background: #090a27; */
  background: var(--text-color);
}

.copyright-link-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.copyright-text p {
  font-size: 13px;
  font-weight: 500;
  color: #b8b6b6;
  margin-bottom: 0;
}

.copyright-text p a {
  color: #fff;
}

.copyright-link-list li {
  position: relative;
  padding-right: 14px;
  margin-right: 14px;
}

.copyright-link-list li::after {
  content: "|";
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  /* color: #282a6b; */
  color: var(--text-color);
  font-size: 13px;
  font-weight: 500;
}

.copyright-link-list li a {
  font-size: 13px;
  font-weight: 500;
  color: #e5e5e5;
}

.copyright-link-list li:last-child {
  padding-right: 0;
  margin-right: 0;
}

.copyright-link-list li:last-child::after {
  display: none;
}

.copyright-link-list li a:hover {
  color: var(--purple-blue-color);
  transition: all 0.3s ease-out 0s;
}

.copyright-wrap {
  /* background: #090a27; */
  background: var(--text-color);
  padding: 10px 0 10px 0;
}

@media (max-width: 2400px) {
  .container {
    max-width: 100% !important;
  }
}

@media (max-width:768px) {
  .count_wrapper label {
    font-size: 18px !important;
  }
}

@media (max-width: 500px) {
  .statistics_page {
    padding: 40px 0 0 0  !important;
    top: -120px
  }
 
  .ai-img-top {
    max-width: 100% !important;
    height: 100% !important;
  }
}

@media (max-width: 300px) {
  .ai-img-top {
    max-width: 200px !important;
    height: 100% !important;
  }
}

.ai-banner-section {
  padding: 10px;
  padding-right: 5%;
  flex-wrap: wrap-reverse;
}

/* Footer END */

.large-icon-btn.btn {
  /* margin: 20px 0px 0px 0px; */
  padding: 25px;
  min-height: 278px;
  width: 380px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 30px rgb(240, 240, 240);
  transform: translateY(0);
  transition: 0.3s linear;
}

.large-icon-btn.btn:hover {
  transform: translateY(-7px);
}

.large-icon-btn.btn .icon {
  margin-bottom: 15px;
}

.ai-img-div {
  display: flex;
  margin: 40px 0px 0px 0px;
  /* flex-direction: row-reverse; */
}

.generate-img-div {
  display: flex;
  justify-content: center;
}

@media (min-width: 450px) {
  .our-work-justify {
    justify-content: center !important;
  }

  .footer-justify-between {
    justify-content: space-between !important;
    padding-right: 20px;
  }
}

.footer-row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
  gap: 50px;
}

.footer-row>* {
  flex-shrink: 0;
  /* width: 100%; */
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.App {
  display: flex;
  flex-direction: column;
  /* gap: 50px; */
}

.meetpi {
  font-weight: 900;
}

.h6introcontent {
  color: black !important;
}

.generate-img {
  width: 70%;
}

.generate-div {
  padding-left: 10%;
  padding-top: 5%;
}

h2.twitterheadertitle {
  font-size: 2.5rem !important;
  font-weight: 500;
}

.h1trainedquality {
  font-size: 3rem;
  padding: 0 25%;
}

.tti-img {
  height: 300px;
  width: 305px;
  margin: 0px 10px 0px 10px;
}

.imageai-div {
  display: flex;
  flex-direction: column;
  background-image: url(../../public/assets/BlogBackground.png);
  height: 600px;
  align-items: center;
}

.tts-imgrow {
  padding: 0px 150px 0px 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  /* position: absolute; */
  z-index: 2;
  direction: ltr;
  bottom: -92px;
  /* left: 50px; */
  right: 0;
}

#imageai {
  position: relative;
  /* margin-bottom: 100px; */
}

@media only screen and (max-width: 1450px) {
  .tti-img {
    height: auto;
    width: 20vw;
    margin: 0;
    float: left;
  }

  .imageai-div {
    height: 350px;
  }

  .tts-imgrow {
    position: relative;
    padding: 0;
    height: 100%;
    bottom: 0;
    display: flex;
    margin-top: 25px;
    flex-wrap: wrap;
  }

  .imageai-div {
    height: 100%;
  }

  #imageai {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 1000px) {
  .tti-img {
    height: auto;
    width: 22vw;
    margin: 0;
    float: left;
  }

  .imageai-div {
    height: 350px;
  }

  .tts-imgrow {
    position: relative;
    padding: 0;
    height: 100%;
    bottom: 0;
    display: flex;
    margin-top: 25px;
    flex-wrap: wrap;
  }

  .imageai-div {
    height: 100%;
  }

  #imageai {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 800px) {
  .steps-container.btn {
    margin-top: 30px;
  }

  .content-wit {
    margin-bottom: 40px;
  }


  .generate-div {
    padding-top: 0 !important;
    margin-top: 0 !important;
    padding-bottom: 30px;
    margin-bottom: 0;
  }


  .h1header,
  .email-header {
    font-size: 2rem;
  }

  .generate-img {
    padding-top: 0;
    padding-bottom: 0;
  }

  .whatsitcontainer {
    margin-top: 150px !important;
  }

  .h1trainedquality {
    font-size: 2.5rem;
    padding: 0 25%;
  }

  .blogbtn {
    min-width: 300px !important;
    max-width: 300px !important;
  }

  .h1ourwork {
    font-size: 2.5rem;
  }

  .tti-img {
    height: auto;
    width: 35vw;
    margin: 0;
    float: left;
  }

  .imageai-div {
    height: 350px;
  }

  .tts-imgrow {
    position: relative;
    padding: 0;
    height: 100%;
    bottom: 0;
    display: flex;
    margin-top: 25px;
    flex-wrap: wrap;
  }

  .imageai-div {
    height: 100%;
  }

  #imageai {
    margin-bottom: 0;
  }
}

.loader-1 {
  height: 32px;
  width: 32px;
  -webkit-animation: loader-1-1 4.8s linear infinite;
  animation: loader-1-1 4.8s linear infinite;
}

@-webkit-keyframes loader-1-1 {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes loader-1-1 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loader-1 span {
  display: inline;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  clip: rect(0, 32px, 32px, 16px);
  -webkit-animation: loader-1-2 1.2s linear infinite;
  animation: loader-1-2 1.2s linear infinite;
}

@-webkit-keyframes loader-1-2 {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(220deg);
  }
}

@keyframes loader-1-2 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(220deg);
  }
}

.loader-1 span::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 32px;
  width: 32px;
  clip: rect(0, 32px, 32px, 16px);
  border: 3px solid #fff;
  border-radius: 50%;
  -webkit-animation: loader-1-3 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
  animation: loader-1-3 1.2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}

@-webkit-keyframes loader-1-3 {
  0% {
    -webkit-transform: rotate(-140deg);
  }

  50% {
    -webkit-transform: rotate(-160deg);
  }

  100% {
    -webkit-transform: rotate(140deg);
  }
}

@keyframes loader-1-3 {
  0% {
    transform: rotate(-140deg);
  }

  50% {
    transform: rotate(-160deg);
  }

  100% {
    transform: rotate(140deg);
  }
}

.meetpi {
  color: white;
}

.ai-banner-section>* {
  color: white;
}

.h1header {
  font-size: 45px;
  color: white;
}

#image-loader {
  height: 10px;
  margin-top: 10px;
  padding: 0;
  width: 90%;
}

/*-------------------------My CC----------------------------*/
.intro_banner {
  position: relative;
  width: 100%;
  height: 100%;
}

.difRopot {
  min-width: 150%;
  min-height: 150%;
}

.ai-img-top-inner {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: fixed;
  background-color: #fff;
  border: 3px solid #fff;
  padding: 5px;
  box-shadow: 1px 0px 8px #80808061;
  top: 30px;
  animation: rightToLeft 2s ease-in 1s;
  animation-iteration-count: 1;
  left: 180px;
  z-index: 1000;
  transition: transform 1s ease-in-out;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.ai-img-top-inner2 {
  display: none;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: fixed;
  background-color: #fff;
  border: 3px solid #fff;
  padding: 5px;
  box-shadow: 1px 0px 8px #80808061;
  top: 30px;
  right: 30px;
  /* animation: rightToLeft 3s ease-in 1s; */
  z-index: 1000;
  transition: transform 1s ease-in-out;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.ai-img-top-inner3 {
  display: none;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: fixed;
  background-color: #fff;
  border: 3px solid #fff;
  padding: 5px;
  box-shadow: 1px 0px 8px #80808061;
  top: 30px;
  /* animation: rightToLeft 3s ease-in 1s; */
  /* right: 30px; */
  z-index: 1000;
  transition: transform 1s ease-in-out;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.ai-img-top-inner::after {}

.visible_avatar_inner {
  transition: opacity 0.5s ease-in-out;
}

.ai-img-top {
  position: absolute;
  right: 0px;
  left: 0px;
  /* top: -173px; */

  bottom: -197px;
  z-index: 9;
  width: 65%;
}

.ai-img-left {
  position: absolute;
  z-index: 10;
}

.ai-img-center {
  position: absolute;
  z-index: 8;
  bottom: 126px;
  left: 133px;
  width: 55%;
}

.h6header {
  font-size: 22px;
  width: 70%;
  color: white;
}

.ai-banner-section {
  align-items: center;
}

.h6introcontent {
  width: 70%;
  font-size: 22px !important;
}

.h1eh {
  font-size: 45px !important;
}

.avatar_inner {
  /* transition: all 0.5s ease; */
  /* visibility: hidden; */
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.real_email_input {
  box-shadow: 1px 0px 8px #80808085;
  padding: 8px;
  border-radius: 15px;
  padding: 15px 10px 15px 10px;
}

.h6new {
  font-size: 22px;
}

.formWrapper {
  padding: 10px;
  gap: 10px;
}

.force_arabic_font {
  font-family: "Tajawal" !important;
}
.force_english_font {
  font-family: "Poppins" !important;
}

.image_itself {
  width: 80%;
  display: block ;
}

.image_title {
  text-align: center;
  font-size: 22px;
  color: #1171d8;
}

@media (min-width: 991px) {
  .pop_login_signin{
    padding: 0 13%;
  }
}
  

.PhoneInput {
  outline: none;
  border: unset !important;
}

.PhoneInputInput {
  outline: none;
}

.otpInput input {
  width: 55px;
  border-radius: 10px;
  height: 60px;
  border: 1px solid black;
}

.otp_number_info {
  display: flex;
  align-items: center;
  gap: 10px;
}

.otp_number_info label {
  font-size: 22px;
}

.otp_number_info label:nth-child(2) {
  font-size: 22px;
  color: #1171d8;
}

.otpForm h2 {
  width: 80%;
  text-align: center;
}

.otpForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.otp_number_info button {
  display: flex;
  margin-bottom: 4px;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border: none;
  background-color: transparent;
}

.otp_number_info button img {
  height: 22px;
  width: 22px;
}

.PhoneInputInput:focus {
  outline: none;
}

.PhoneInputInput:active {
  outline: none;
}

.pop_up_submitBTN {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 5px;
  background-color: #1171d8;
  min-height: 38px;
  min-width: 45px;
}

.modal_img_wrapper {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.google_btn {
  /* display: grid !important; */
  /* width: 90% !important; */
  padding: 3px !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: transparent !important;
  border: 1px solid gray !important;
  color: gray !important;
  position: relative;
  min-height: 35px;
}

.facebook {
  padding: 3px !important;
  /* width: 90% !important; */
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: transparent !important;
  border: 1px solid gray !important;
  color: gray !important;
  position: relative;
  min-height: 35px;
}

.apple {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  background-color: #000 !important;
  color: #fff !important;
  min-height: 35px;
  padding: 3px !important;
}

.google_btn img,
.facebook img {
  height: 25px;
  width: 25px;
  position: absolute;
  left: 3px;
}

.apple img {
  height: 25px;
  width: 25px;
  margin-bottom: 3px;
}

.google_btn label,
.facebook label,
.apple label {
  cursor: pointer;
  margin-left: 17px;
}

.auth_modal .modal-content {
  border-radius: 20px;
  border: none;
}

.auth_modal .modal-body {
  padding: 16px ;
  align-items: center;
}

.btnH {
  position: relative;
  display: inline-block;
  color: #155A96;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  padding: 0px;
}

.btnH span {
  position: relative;
  display: inline-block;
  border: none;
  color: #fff;
  /* font-family: 'Poppins', sans-serif; */
  top: 0;
  left: 0;
  width: 100%;
  transition: 0.3s;
}

.btn-1 span {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border: none;
  color: #155A96;
  transition: 0.2s 0.1s;
}

.btn-2 span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 22px;
  height: 100%;
  width: 100%;
  border: none;
  color: #155A96;
  transition: 0.2s 0.1s;
}

.btnH {
  background-color: transparent;
  color: #155A96;
}

:active,
:hover,
:focus {
  outline: 0 !important;
  outline-offset: 0;
}

::before,
::after {
  position: absolute;
  content: "";
}

.btn-1 span:hover {
  border: 1px solid #fff;
  color: #fff;
  transition: 0.1s 0.1s;
}

.btn-2 span:hover {
  border: 1px solid #fff;
  color: #fff;
  transition: 0.1s 0.1s;
}

.btnH.hover-filled-slide-down::before {
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
}

.btnH.hover-filled-slide-down:hover::before {
  height: 0%;
}

.btn-1 span {
  color: #155A96;
}

.btn-2 span {
  color: #fff;
}

.pop_sign_up_form {
  row-gap: 8px;
}

.appleBtn p {
  color: #fff !important;
}

#canvas {
  width: 100%;
  height: 100%;
}

/*--- btn-1 ---*/
.btn-1::before {
  background-color: #fff;
  border: none;
  border-radius: 5px;
  color: #155A96;
  transition: 0.3s ease-out;
}

.btn-2::before {
  background-color: var(--purple-blue-color);
  border: none;
  border-radius: 5px;
  color: #fff;
  transition: 0.3s ease-out;
}

@media (max-width: 770px) {
  .h6header {
    width: 100% !important;
  }
  .customH6 {
    font-size: 15px !important;
  }
}

@media (max-width: 900px) {
  .fs-5 {
    font-size: 15px !important;
  }

  /* .btns_wrapper button {
    display: none !important;
  } */

  .difRopot {
    min-width: 100% !important;
    min-height: 100% !important;
  }

  .thinking-img {
    display: none;
  }
  .email-section {
    margin-bottom: 0px !important;
  }

  .generate-img {
    width: 80%;
  }

  .generate-img-div {
    margin-bottom: 0px !important;
  }

  .h6new {
    margin-top: 6px !important;
  }

  .emaildiv {
    width: 100% !important;
  }

  .subject-arrow-img {
    right: -142px !important;
    width: 160px !important;
    bottom: 188px !important;
    transform: rotate(307deg) !important;
  }

  .email-section {
    flex-direction: column-reverse !important;
  }
}

@keyframes rightToLeft {
  0% {
    left: 180px;
    right: auto;
  }

  50% {
    right: auto;
    top: 250px;
    left: calc(50% - 35px);
  }

  90% {
    top: 30px;
    left: calc(100% - 140px);
    /* right: auto; */
  }

  100% {
    top: 30px;
    left: auto;
    right: 30px;
  }
}

.lngBTN {
  color: #fff;
  font-size: 22px;
  font-weight: 800;
  margin: 5px;
}

.homepage {
  margin-top: 88px;
}
.count_wrapper {
  padding: 40px 0;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.1);
    border-radius: 38px;
  background-color: #ffffff;
  margin: auto;
  width: 85%;
}
.count_wrapper label {
  font-size: 25px;
}



.counter_Component {
  position: relative;
}
/* .counter_Component::before {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: calc(50% + 15px);
  height: 5%;
  width: 30px;
  box-shadow: -25px -4px 4px 0 rgb(0 0 0 / 51%);
  transform: skewX(59deg);
} */
.white_paper {
  /* height: 85vh; */
  width: 70%;
  background-color: #fff;
  z-index: 1000;
  position: relative;
  border-radius: 20px;
  /* margin-top: 80px; */
  box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.612);
  padding: 40px;
  overflow-y: auto;
}
.white_paper::-webkit-scrollbar {
  width: 6px;
  height: 4px;
  position: absolute;
  right: -100px;
}
/* Track */
.white_paper::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 6px;
  
}

/* Handle */
.white_paper::-webkit-scrollbar-thumb {
  background: linear-gradient(to top, #d851ef, #1877f2);
  border-radius: 6px;
}

/* Handle on hover */
.white_paper::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/*-------------------------My CC----------------------------*/

.MsoNormal, .MsoListParagraphCxSpFirst, .MsoListParagraphCxSpMiddle, .MsoListParagraphCxSpLast, .MsoListBulletCxSpMiddle, .MsoListBulletCxSpFirst, .MsoListBulletCxSpLast {
  font-size: 18px;
  color: #000;
}
.MsoListParagraphCxSpFirst, .MsoListParagraphCxSpMiddle, .MsoListParagraphCxSpLast, .MsoListBulletCxSpMiddle, .MsoListBulletCxSpFirst, .MsoListBulletCxSpLast {
  padding-left: 5%;
  margin-bottom: 5px;
}
h1 {
  margin: 0px;
}
.captchaModal .modal-content {
  border: unset;
  box-shadow: unset;
  background-color: transparent;
}
.captchClass {
  border: unset !important;
}

.browserHelpBtn {
  width: 100px;
  background: none;
  border: 1px solid #c1c1c1;
  border-radius: 10px;
  padding: 5px;
  align-self: center;
}
.category_card:hover {
  box-shadow: none;
  outline: 2px solid #4472f16c;
  /* border-bottom: 6px solid #4472f16c; */
  transition: border 0.2s ease-out;
}
.category_card {
  background-color: #fff;
  box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.203);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  gap: 5px;
  width: 100%;
  height: 210px;
}
.category_card .cat_img {
  height: 82%;
  width: 35%;
  object-fit: contain;
}
.category_card label {
  text-decoration: none;
  color: #000;
  font-weight: 500;
  margin-top: 10px;
  cursor: pointer !important;
  text-align: center;
}
@media (max-width: 768px) {
  .category_card {
    /* width: auto; */
    height: 182px;
  }
}
.category_card {
  height: auto ;
}
.category_card .cat_img {
  max-height: 60px;
  min-height: 60px;
}
.category_card .cat_imgmg {
  width: 60%;
  height: auto;
  max-height: 73px;
}
.category_card label {
  font-size: 12px;
}
.all_images_wrapper {
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 10px; */
}
.all_images_wrapper {
  padding: 10px;
  display: flex;
  row-gap: 40px;
}
.videoBtnEffect span {
  background: linear-gradient(90deg, #151723, #23273a);
  border-radius: 15px;
  padding: 5px 10px 5px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
  animation-name: btnPosition;
  animation-duration: 4s;
  animation-delay: 2s;
}
.videoBtnEffect span label {
  color: #fff;
  margin: 0px;

}
@keyframes btnPosition {
  from {bottom: -40px;}
  to {bottom: 5px;}
}
.swiper-button-next, .swiper-button-prev {
  color: #fff !important;
}
.prev, .next {
  position: absolute;
  top: 45%;
  z-index: 100;
  cursor: pointer;
}
.prev {
  left: -16px;
}
.next {
  right: -16px;
}
@media (max-width: 900px) {
  .textEffect {
    opacity: 1 !important;
  }
  .textEffect span {
    background: linear-gradient(90deg, #151723, #23273a);
    border-radius: 15px;
    padding: 5px 10px 5px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in;
    animation-name: btnPosition;
    animation-duration: 4s;
    animation-delay: 2s;
  }
  .textEffect span label {
    color: #fff;
    margin: 0px;
  
  }
}
@media (max-width: 700px) {
  .customVideo {
    height: 270px !important;
  }
}
@media (max-width: 500px) {
  .customVideo {
    height: 225px !important;
  }
}

.plan-type {
  /* background-color: #fafdff; */
  min-height:100%;
}
.plan-type {
  /* background-color: #fafdff; */
}

.plan-type .filter_wrapper button {
  background-color: transparent;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 12px;
}

.plan-type .filter_wrapper a svg {
  width: 21px;
  height: 17px;
}

.plan-type .filter_wrapper button {
  color: #1877f2;
  border: 0px solid #1877f2;
  border-radius: 20px;
  transition: 0.5s;
}

.plan-type button.arabicFont,
button.englishFont {
  border: 1px solid #fff;
  padding: 4px 8px 9px;
  border-radius: 11px;
}

.plan-type .filter_wrapper button:hover {
  color: #1877f2;
  border: 1px solid #1877f2;
}

.plan-type .filter_wrapper button.active {
  background-color: #1877f2;
  color: #fff;
  border: unset;
}

.plan-type .rc-switch-checked {
  /* border: 1px solid transparent;
  background-color: #d851ef !important; */
  background: #FFFFFF;
border: 0.5px solid #D7D7D7;
box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
}
.plan-type .rc-switch {
  /* border: 1px solid transparent;
  background-color: #1dc7ea;
  box-shadow: none; */
  background: #FFFFFF;
border: 0.5px solid #D7D7D7;
box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
}
.save-10-head{
  direction: ltr;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.rc-switch:after{
  top: 1.3px;
}