.plans_card {
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 1px 0px 8px rgb(128 128 128 / 20%);
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;
  /* transform: scaleY(0.9); */
}
.plans_categories_wrapper {
  overflow: hidden;
  display: flex;
  direction: ltr;
  align-items: flex-start;
  justify-content: space-between;
  /* min-height: 90px; */
  padding: 5px;
  padding-bottom: 0px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 1px 0px 8px rgba(128, 128, 128, 0.269);
  margin-top: 60px;
}
.plans_card_sort {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #000;
  width: 30%;
  padding: 5px;
  /* min-height: 99px; */
}
.plans_card_sort .plan_btn_filter,
.plans_card_sort span {
  opacity: 0.4;
}
.plans_categories_wrapper a {
  text-decoration: none;
}
.plans_categories_wrapper a:focus,
.plans_categories_wrapper a:active,
.plans_categories_wrapper a:hover {
  color: #000 !important;
}
.plans_card_sort.active {
  transition: opacity 0.3s ease-in-out;
  border-bottom: 4px solid #006ceb;
  opacity: 1;
}
.plans_card_sort:hover {
  transition: opacity 0.3s linear;
  border-bottom: 4px solid #006ceb;
}
.plans_card_sort:hover .plan_btn_filter {
  color: #000 !important;
  opacity: 1;
}
.plans_card_sort.active .plan_btn_filter {
  color: #000 !important;
  opacity: 1;
}
.plan_btn_filter img {
  height: 25px;
  width: 25px;
}
.plans_categories_wrapper span {
  font-size: 13px;
}
.plan_btn_filter {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.plans_card_sort span {
  text-align: center;
}
.plan_name {
  padding: 8px;
  background-color: #80808057;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 65px;
  width: fit-content;
  border-radius: 5px;
}
.plan_name label {
  font-size: 14px;
  font-weight: 600;
}
.plan_name label,
.upper_info p {
  margin: 0px;
}
.plan_details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 6px;
  list-style: none;
}
.plan_details li {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
  gap: 6px;
  font-size: 14px;
}
.plan_details img {
  margin-bottom: 2px;
}
.plan_btn_filter img {
  height: 25px;
  width: 25px;
}
.plan_btn_filter {
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}
.pop_wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}
.upper_info {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.upper_info p {
  font-size: 14px;
  color: #000;
}
.priceWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.priceWrapper h1 {
  margin: 0px;
  font-weight: 700;
  color: #000;
  font-size: 52px;
}
.priceWrapper label {
  margin: 0px;
  font-size: 13px;
  font-weight: 500;
}
.priceWrapper {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.subs_button {
  min-height: 36px;
  border: unset;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
}
.subs_button:hover {
  background-color: #fff !important;
  border: 1px solid #000;
  color: #000;
  transition: all 0.2s ease-out;
}
.subs_button:disabled {
  background-color: #fff !important;
  border: 1px solid #000;
  color: #000;
  transition: all 0.2s ease-out;
}
.textbutton_wrapper__mgZn4 {
  border: var(--border);
  color: var(--text-color);
  background: var(--background-color);
  border-radius: var(--border-radius);
  transition: all 0.3s ease-in-out;
  padding: var(--padding);
  padding: var(--padding);
  background-size: 200% 100%;
  box-shadow: var(--shadow);
}
.textbutton_wrapper__mgZn4 p {
  font-size: 18px;
  text-align: center;
  font-weight: var(--font-weight);
  padding: 0;
  margin: 0;
}
@media (max-width: 465px) {
  .plans_categories_wrapper {
    /* flex-direction: column; */
    align-items: center !important;
    justify-content: center;
    row-gap: 10px;
  }
  .plans_card_sort span {
    display: none;
  }
  .plan_btn_filter {
    font-size: 11px;
  }
  .plan_btn_filter img {
    height: 15px;
    width: 15px;
  }
  .plans_card_sort {
    width: 90% !important;
  }
}

.pricing-accordion-wrapper {
  height: 100%;
  padding: 50px 0;
}
.pricing {
  margin: 100px 0 0 0;
}

.pricing h1 {
  text-align: center;
  font-weight: bold;
}

.pricing-accordion {
  border-radius: 10px !important;
  margin: 30px auto !important;
  padding: 5px;
  box-shadow: 0px 0px 17px 0px rgb(0 0 0 / 10%) !important;
}

.css-1byhwgv-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  border: 0.5px solid #1dc7ea;
}
.coding-languages {
  height: 100%;
  padding: 50px 0;
  background: linear-gradient(
    0.3deg,
    rgba(38, 22, 110, 0.788) -68.19%,
    rgb(2 2 4) 92.28%,
    rgb(2 2 3) 107.42%
  );
  width: 100%;
  margin: 100px auto;
  border-radius: 70px;
  position: relative;
  text-align: center;
}

.coding-languages h1,
h3 {
  color: white;
  font-weight: bold;
  text-align: center;
}
.coding-languages h6 {
  color: white;
  text-align: center;
  margin-bottom: 20px;
}
.coding-languages h4 {
  color: #9ea2d1;
  padding: 20px;
  text-align: center;
  margin: auto;
}

.coding-languages button {
  background: linear-gradient(227.23deg, #3cc8eb -5.82%, #aa6bfc 96.99%);
  border: none;
  border-radius: 47px;
  color: white;
  font-weight: 600;
  padding: 10px 25px;
  font-size: 25px;
}

.pricing-slider {
  pointer-events: none;
  margin: 20px 0;
}
.pricing-accordion h1 {
  font-size: 1.2rem;
}
.marquee-item-cards {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(255, 255, 255, 0.421);
  border-radius: 10px;
  padding: 0 10px;
  width: 100%;
  margin: 0 10px;
}
.marquee-item-cards div {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.marquee-item-cards img {
  background-color: white;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 0 2px;
}
.marquee-item-cards h4 {
  color: white;
  font-size: 1.2rem;
}
.reviews {
  width: 100%;
  margin: 100px auto;
  overflow: hidden;
}

.reviews-card-wrapper {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.reviews-cards {
  border-radius: 15px;
  border: 0.5px solid #3cc8eb;
  width: 450px;
  height: 100%;
  padding: 20px 20px 50px 20px;
  margin: 50px 0;
  min-height: 53vh;
}
.reviews-card-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.reviews-card-hero {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.reviews-card-hero h5,
h6 {
  margin: 0;
}
.reviews-card-hero h6 {
  font-size: 0.8rem;
}
.reviews-card-content h5 {
  font-size: 1rem;
  font-weight: bolder;
}

.reviews-card-head img {
  background-color: #aa6bfc;
  border-radius: 50%;
  object-fit: cover;
  width: 80px;
  height: 80px;
  margin-right: 10px;
}

.review-star {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #bcbcbc2b;
  padding: 5px 10px 5px 15px;
  border-radius: 15px;
}

.review-star h5 {
  margin: 0;
}
.marquee-container {
  white-space: nowrap;
  overflow: hidden;
}

.marquee-items {
  display: inline-block;
  display: flex;
  margin-right: 50px;
  animation: marquee 10s linear infinite;
}

.save10 {
  /* background-color: #cbffd0;
  border-radius: 4px;
  color: #436547;
  font-size: 0.8rem;
  font-weight: 600;
  padding: 0px 15px;
  position: absolute;
  line-height: 30px;
  margin-left: 10px; */
  margin-left: 10px; 
  color: #D852F0;
  font-size: 0.8rem;
  font-weight: 600;
  /* position: absolute; */
}

.rc-switch {
  background: #1dc7ea !important;
  box-shadow: none !important;
}
.rc-switch-checked {
  background: #d751ef !important;
  border: white;
  box-shadow: none !important;
}

/* .rc-switch-checked {
  border: 1px solid transparent !important;
  background-color: #d851ef !important;
}

.rc-switch {
  border: 1px solid transparent !important;
  background: #1dc7ea !important;
} */

@media (max-width: 976px) {
  .save10 {
    padding: 0 3px;
  }
  .coding-languages {
    border-radius: 40px;
  }
  .coding-languages h1 {
    font-size: 2rem;
  }
  .coding-languages h4 {
    font-size: 1rem;
  }
}
@media (max-width: 600px) {
  .pricing h1 {
    font-size: 1.5rem;
  }
  .coding-languages {
    border-radius: 40px;
  }
  .coding-languages h1 {
    font-size: 1.2rem;
  }
  .coding-languages h4 {
    font-size: 0.64rem;
  }
  .coding-languages h3 {
    font-size: 1.2rem;
  }
  .coding-languages h6 {
    font-size: 0.8rem;
  }
  .coding-languages button {
    font-size: 15px;
  }
  .pricing-accordion h1 {
    font-size: 1rem;
  }

  .reviews-cards {
    min-width: 340px;
  }
}

.araby-gpt-plan-text {
  font-weight: 700;
    margin-bottom: 0 !important;
    text-align: center;
    
    background: linear-gradient(to right, #69aaf4 10%, #005ac0 60%, #ace0f9 60%, #FFF 20%);
    background-size: 200% auto;
    
    color: #000;
    background-clip: text !important;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    
    animation: shine 1s linear infinite;
    @keyframes shine {
      to {
        background-position: 200% center;
      }
    }
  }

 
.newPlanCard:hover {
  transform: scale(1.01);
  outline: 2px solid #005EFF !important;
}
.newPlanCard:hover .plan_name {
  background-color: #005EFF;
}
.newPlanCard:hover .subs_button {
  background-color: #005EFF;
}
.newPlanCard:hover .plan_name h6 {
color: white !important;
} 
@media (max-width: 500px) {
  .toggle_button_plans{
    width: 300px !important;
  }
}
.toggle_button_plans{
  border: 0.5px solid #D7D7D7;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
  border-radius: 29px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 400px;
  margin:10px auto;
}
.toggle_button_plans_1{
  background: #005EFF;
  color: white;
  padding: 8px;
  width: 50%;
  border: none;
  font-weight: 700;
 box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.06);
   border-radius: 29px;;
 }
 .toggle_button_plans_2{
   background:transparent;
   border: none;
   width: 50%;
   font-weight: 700;
   padding: 8px;
   text-align: center;
 }
 .sub_pro_button{
  min-height: 36px;
  border: unset;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  background: linear-gradient(90deg, #D852F0 0%, #1A77F3 100%)
}

.sub_pro_button:hover{
  background: #fff !important;
  border: 1px solid #000;
  color: #000;
  transition: all 0.2s ease-out;
}