.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 90%;
  max-width: 500px;

  .logo {
    width: 100px;
    margin-bottom: 3%;
  }

  .text {
    margin-bottom: 5%;
    color: grey;
    font-size: clamp(13px, 2vw, 18px);
  }

  .form {
    width: 100%;
    margin-bottom: 5%;

    .textfield:focus {
      border: 2px solid rgba(60, 200, 235, 1);
      box-shadow: 0 0 0 transparent;
    }

    .textfield-error,
    .textfield-error:focus {
      transition: all 0 ease-in-out;
      border: 1px solid red;
      box-shadow: 0 0 0 transparent;
    }
  }
  .error-message {
    color: red;
    font-size: 0.7em;
    font-weight: 500;
  }
  .respone-error-message {
    color: red;
  }

  .backlink-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    a {
      color: rgb(2, 188, 234);
    }
  }

  .otp-input {
    font-size: 22px;
    color: red;
    input {
      color: blue;
    }
  }
}
