.fade-enter-right {
  opacity: 0;
  transform: translateX(-100%);
}
.fade-enter-active-right {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-right {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active-right {
  opacity: 0;
  transform: translateX(100%);
}

.fade-enter-active-right {
  transition: opacity 750ms ease-in-out, transform 750ms;
}
.fade-exit-active-right {
  transition: opacity 1000ms cubic-bezier(0.16, 1, 0.3, 1), transform 1000ms;
}

.fade-enter-left {
  opacity: 0;
  transform: translateX(100%);
}
.fade-enter-active-left {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-left {
  opacity: 1;
  transform: translateX(0%);
}
.fade-exit-active-left {
  opacity: 0;
  transform: translateX(-100%);
}

.fade-enter-active-left {
  transition: opacity 750ms ease-in-out, transform 750ms;
}
.fade-exit-active-left {
  transition: opacity 1000ms cubic-bezier(0.16, 1, 0.3, 1), transform 1000ms;
}
