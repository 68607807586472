.wave-emoji {
  animation: wave 2s linear 0s infinite alternate;
  display: inline-block;
  transform-origin: 70% 70%;
  /* an
      imation-fill-mode: forwards; */
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(-12deg);
  }

  20% {
    transform: rotate(12deg);
  }

  30% {
    transform: rotate(-12deg);
  }

  40% {
    transform: rotate(12deg);
  }

  50% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
