.code-faster-section{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
   
    /* background: #0D1117; */
}

.codefaster-container {
    background: #0D1117;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    /* height: 818px; */

}



.codefaster-left h1 {
    text-transform: uppercase;
    text-align: left;
    color: white;
    font-family: 'Integral CF' !important;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 48px;
    margin-left: 23px;
    margin-bottom: 14px;
    width: 100%;
}

.codefaster-left {
    background-color: #0D1117;
    display: flex;
    border: none;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    /* height: 818px; */
}
.mycode-veiw{
    /* background: red; */
    background: #181F29;
    height: 682px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 98%;
    margin: 15px;
    border-radius: 10px;
    flex-direction: column;
    overflow: hidden;
}
.mycode-veiw img{
    height: 12px;
    margin-left: 7px;
    margin-top: 7px;
    margin-bottom: 7px;
}
.mycode-veiw p{
    color: white;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Poppins';
    background: #252F3F;
    border-radius: 5px;
    padding: 7px;
    width: 97%;
    align-self: center;
    line-height: 22.5px;
    margin-bottom: -32px;

}
.code-fonts{
    font-family: 'PT Mono' !important;
    font-size: 12px !important;
    font-weight: 400 !important;
    line-height: 18px !important;
    letter-spacing: -0.04em !important;
    text-align: left !important;
    text-shadow: none !important;
    color: white !important;
   
}


.codefaster-right {
    position: relative;
    /* background-color: rgb(1, 1, 1); */
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 15px;
    padding: 0px;
    /* height: 818px; */
    flex-direction: column;
    /* background-image: url('../../../public/assets/mac-view.png');
    background-size: 639px 400px;
    background-repeat: no-repeat;
    background-position: 121px 67px; 
            background-position: center;
			background-size: cover;
			position: relative;
			z-index: -1; */
}
.codefaster-right button{
    border: none;
    font-family: 'Poppins';
    border-radius: 10px;
    margin-right: 211px;
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    padding: 10px 35px;
    -webkit-transform: scale(.9);
    transform: scale(.9);
    background: linear-gradient(45deg, #1171d8, #3cc8eb);
}
.codefaster-right button:hover{
    background-color: #02001d;
    background-color: var(--text-color);
    border-radius: 10px;
    color: #fff;
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: all .5s ease;
}
.codefaster-right .arrow{
    height: 43px;
    position: absolute;
    right: 576px;
    top: 308px;
}
.codefaster-right .macview{
   height: 464px;
   position: relative;
}
.codefaster-right .output{
    top: 99px;
    width: 72%;
    height: 398px;
    font-size: 16px;
    /* border: 1px solid #ed1414; */
    padding: 10px;
    overflow: hidden;
    position: absolute;
    color: white;
    font-size: 12px;
    padding: 0px;
}





  /* Styles for extra small devices */
  @media only screen and (min-width: 280px) and (max-width: 375px) {
    .codefaster-left h1{
        font-size: 14px;
        margin-left: 0px;
        /* color: red; */
    }
    .brases-right,.brases-left{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .codefaster-left{
        justify-content: center;
    }
    .mycode-veiw{
        width: 100%;
        height: 781px;
        margin-left: 0px;
        margin-top: 0px;
    }
    .mycode-veiw p{
        width: 90%;
        font-size: 12px;
        line-height: normal;
    }
    .code-showing{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .code-fonts{
        font-size: 9px !important;
        line-height: normal;
    }
   
    .codefaster-right button{
        margin-right: 0px;
        font-size: 12px;
        align-self: center;
    }
    .macview{
        height: 248px !important;
    }
    .codefaster-right .output{
        top: 71px;
        width: 239px;
        height: 213px;
    }
    .banner {
        height: 138px;
    }
    #blinds-window img{
        height: 213px;
    }
    
  }
@media only screen and (min-width: 320px) and (max-width: 321px) {
    .codefaster-right .output{
        width: 272px;
    }

  }
  @media only screen and (min-width: 360px) and (max-width: 365px) {
    .codefaster-right .output{
        width: 292px;
    }

  }
 /* Styles for small devices (smartphones) */
@media only screen and (min-width: 375px) and (max-width: 414px) {
    .codefaster-left h1{
        font-size: 16px;
        margin-left: 0px;
        /* color: yellow; */
    }
    .codefaster-left{
        justify-content: center;
    }
    .brases-right,.brases-left{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .code-showing{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .mycode-veiw{
        margin: 0px;
        height: 747px;
    }

    .codefaster-right .output{
        top: 86px;
    width: 289px;
    height: 213px;
    }
    #blinds-window img{
        height: 213px;
    }
    .codefaster-right button{
        margin-top: 16px;
        margin-right: 0px;
        font-size: 12px;
        align-self: center;
    }
    .macview{
        height: 246px !important;
    }
    .banner {
        height: 153px;
    }
}
@media only screen and (min-width: 414px) and (max-width: 428px) {
    .codefaster-left h1{
        font-size: 16px;
        margin-left: 0px;
        /* color: yellow; */
    }
    .codefaster-left{
        justify-content: center;
    }
    .brases-right,.brases-left{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .code-showing{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .mycode-veiw{
        margin: 0px;
        height: 734px;
    }

    .codefaster-right .output{
        top: 86px;
    width: 289px;
    height: 213px;
    }
    #blinds-window img{
        height: 213px;
    }
    .codefaster-right button{
        margin-top: 16px;
        margin-right: 0px;
        font-size: 12px;
        align-self: center;
    }
    .macview{
        height: 246px !important;
    }
    .banner {
        height: 153px;
    }
}
 
@media only screen and (min-width: 428px) and (max-width: 540px) {
    .codefaster-left h1{
        font-size: 16px;
        margin-left: 0px;
        /* color: yellow; */
    }
    .codefaster-left{
        justify-content: center;
    }
    .brases-right,.brases-left{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .code-showing{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .mycode-veiw{
        margin: 0px;
        height: 734px;
    }

    .codefaster-right .output{
        top: 86px;
    width: 289px;
    height: 213px;
    }
    #blinds-window img{
        height: 213px;
    }
    .codefaster-right button{
        margin-top: 16px;
        margin-right: 0px;
        font-size: 12px;
        align-self: center;
    }
    .macview{
        height: 246px !important;
    }
    .banner {
        height: 153px;
    }

}
@media only screen and (min-width: 576px) and (max-width: 768px) {
    .codefaster-left h1{
        font-size: 14px;
        margin-left: 0px;
        /* color: rgb(70, 10, 160); */
    }
    .brases-right,.brases-left{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .code-showing{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .codefaster-right button{
        margin-right: 0px;
        font-size: 12px;
        align-self: center;
    }
    .macview{
        height: 288px !important;
    }
    .codefaster-right .output{
        top: 74px;
        width: 245px;
        height: 246px;
    }
    #blinds-window img{
        height: 246px;
    }
    .banner {
        height: 183px;
    }
    .threebtn {
        height: 12px !important;
    }
    .arrow {
        height: 17px !important;
        position: absolute;
        right: 329px  !important;
        top: 211px !important;
    }
    .mycode-veiw p{
        
        font-size: 12px;
        line-height: normal;
    }
    .mycode-veiw{
        height: 752px;
    }



}

@media only screen and (min-width: 768px) and (max-width: 800px) {
    .codefaster-left h1{
        font-size: 20px;
        margin-left: 0px;
        /* color: rgb(70, 10, 160); */
    }
    .code-showing{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .codefaster-right button{
        margin-right: 0px;
        font-size: 12px;
        align-self: center;
    }
    .macview{
        height: 288px !important;
    }
    .codefaster-right .output{
        top: 74px;
        width: 326px;
        height: 246px;
    }
    #blinds-window img{
        height: 246px;
    }
    .banner {
        height: 183px;
    }
    .threebtn {
        height: 12px !important;
    }
    .arrow {
        height: 17px !important;
        position: absolute;
        right:338px  !important;
        top: 211px !important;
    }
    
    .mycode-veiw p{
        
        font-size: 12px;
        line-height: normal;
    }
    .mycode-veiw{
        height: 698px;
    }
     
}
@media only screen and (min-width: 800px) and (max-width: 912px) {

    .codefaster-left h1{
        font-size: 20px;
        margin-left: 0px;
        /* color: rgb(160, 10, 10); */
    }
    .code-showing{
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    .codefaster-right button{
        margin-right: 0px;
        font-size: 12px;
        align-self: center;
    }
    .macview{
        height: 288px !important;
    }
    .codefaster-right .output{
        top: 74px;
        width: 339px;
        height: 246px;
    }
    #blinds-window img{
        height: 246px;
    }
    .banner {
        height: 183px;
    }
    .threebtn {
        height: 12px !important;
    }
    .arrow {
        height: 16px !important;
        right: 350px !important;
        top: 211px !important;
    }
    
    .mycode-veiw p{
        
        font-size: 12px;
        line-height: normal;
    }
    .macview{
        height: auto;
    }
   

}
@media only screen and (min-width: 992px) and (max-width: 1024px) {

    .codefaster-left h1{
        font-size: 27px;
        /* color: rgb(160, 10, 10); */
    }
    .codefaster-right button{
        margin-right: 0px;
        align-self: center;
    }
    .mycode-veiw{
        height: 717px;
    }

    .codefaster-right .output{
        top: 99px;
        width: 422px;
        height: 397px;
    }
    #blinds-window img{
        height: 397px;
    }
    .arrow {
        height: 17px !important;
    right: 441px !important;
    top: 211px !important;
    }

}
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
    .codefaster-left h1{
        font-size: 27px;
        /* color: rgb(160, 10, 10); */
    }
    .codefaster-right button{
        margin-right: 0px;
        align-self: center;
    }
    .codefaster-right .output {
        width: 435px;
    }
    .mycode-veiw{
        height: 717px;
    }
    .arrow{
        height: 19px !important;
        right: 450px !important;
        top: 322px !important;
    }

}
@media only screen and (min-width: 1280px) and (max-width: 1440px) {
    .arrow{
        height: 25px !important;
        right: 563px !important;
        top: 319px !important;
    }
    .codefaster-right .output {
        width: 544px;
    }
    

}


  





