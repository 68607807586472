.blog {
    padding-bottom: 65px;
    // padding-left: 10%;
    
    .banner {
        width: 90%;
        background-image: url(../../../public/assets/BlogBackground.png);
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 20px;
        padding: 2%;
        
        >div:first-child {
            display: flex;
            flex-direction: column;

            p:first-child {
                margin: 0;
                color: #FFFFFF;
                // font-size: 45px;
                // font-weight: 600;
                line-height: 1.2;
            }
        }

        >div:nth-child(2) {
            padding-top: 2%;
        }
    }
}

@media screen and (max-width: 728px) {
    .blog {
        padding-left: unset !important;
        padding-right: unset !important;

        .banner {
            width: 100%;
        }
    }

    .banner {
        padding: 10px !important;
    }
}