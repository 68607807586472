.steps-banner.justify-content-center {
  margin: 20px;
  border-radius: 50px;
  height: 64px;
  min-width: 150px;
  max-width: 300px;
  box-shadow: 0 0 30px rgb(240, 240, 240);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.steps-banner.justify-content-center h5 {
  font-size: 16px;
  font-weight: 500;
  margin: 0 20px 0 20px;
}

.steps-container.btn {
  position: relative;
  /* margin: 50px 10px 30px 10px; */
  padding: 50px 7.5px 30px 7.5px;
  height: 280px;
  /* width: 415px; */
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 0 30px rgb(240, 240, 240);
  transform: translateY(0);
  transition: 0.3s linear;
  /* height: 100%; */
  margin-top: 60px;
}

.steps-container.btn:hover {
  transform: translateY(-7px);
}

.steps-container.btn div {
  margin: 0px;
  position: absolute;
  top: -40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 58px;
  width: 58px;
  border-radius: 50px;
  background: #fff;
  box-shadow: 0 0 30px rgb(240, 240, 240);
}

.steps-container.btn h3 {
  margin: 0;
  padding: 0 10px 0 10px;
}

.steps-container.btn div h1 {
  margin: 0;
  font-size: 2rem;
}

.steps-container.btn img {
  max-width: 100%;
  max-height: 100%;
}

@media only screen and (max-width: 800px) {
  .steps-container.btn {
    margin-top: 30px;
  }

  .content-wit {
    margin-bottom: 40px;
  }

  .whatsitcontainer {
    margin-top: 150px !important;
  }
}
