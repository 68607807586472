.contact-modal .modal-content {
    border-radius: 50px;
    border: none;
    height: 100%;
    display: flex;
}

.contact-modal .modal-body {
    padding: 30px;
    border-radius: 50px;
    background-color: #FBF8FF;
    align-items: center;
}

.contact-modal .contact-us-section {
    background-color: #FBF8FF;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    min-height: 500px;
    /* max-height: 700px; */
}

@media (max-height: 1000px) {

    .contact-modal .cuh1-contactus {
        color: #A5A9B5;
        font-size: 2rem;
    }

    .contact-modal .cuh1-contactus-caption {
        color: black;
        font-size: 2rem;
    }

}


.contact-us-section {
    background-color: #FBF8FF;
    height: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.contact-us-text-div {
    align-self: center;
    margin-top: 30px;
}

.contact-us-outer-form {
    align-self: center;
    width: 80%;
    border: 1px solid #f3ecec;
    padding: 10px;
    height: 400px;
    border-radius: 25px;
}

.contact-us-inner-form {
    width: 100%;
    background-color: white;
    height: 100%;
    border-radius: 25px;
    display: flex;
    flex-wrap: wrap;
    padding: 30px;
    gap: 20px;
}

.form-input-contact {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.textarea-contact {
    width: 100%;
}

.form-input-contact input {
    background-color: #f4f4f48a;
    border-radius: 38px;
    height: 50px;
    width: 100%;
    outline: none;
    padding-left: 25px;

}

.form-input-contact textarea {
    border: none;
    background-color: #f4f4f48a;
    border-radius: 20px;
    /* height: 50px; */
    width: 100%;
    padding: 15px;
    outline: none;


}

.name-email-input {
    width: 100%;
    display: flex;
    gap: 50px;
}

.contact-us-section {
    background: #fbf8ff;
    padding-top: 50px;
    margin-bottom: 100px;
    height: 100%;
}

.cu-center {
    justify-content: center;
    text-align: center;
}

.cuh1-contactus {
    color: #A5A9B5;
    font-size: 2.75rem;
}

.cuh1-contactus-caption {
    color: black;
    font-size: 2.75rem;
}

.cu-divborder {
    border-radius: 25px;
    border: 1px solid #d3d3d3;
    padding: 15px;
    margin: 2%;
}

.cu-divcontents {
    background: white;
    box-shadow: 0 0 10px #f3f3f3;
    border-radius: 10px;
}

input.cu-input {
    background: #f7f8f8;
    padding: 15px;
    width: 100%;
    border-radius: 15px;
    margin: 0px;
}

textarea.cu-input {
    background: #f7f8f8;
    padding: 15px;
    width: 100%;
    border-radius: 25px;
    /* margin: 10px; */
    margin: 0px;
    border: 0;
    max-height: 150px;
}

button.cu-btn {
    width: 200px;
}

.cu-row {
    padding: 30px;
}

.cu-row-btn {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

@media only screen and (max-width: 600px) {
    .contact-us-section {
        margin-bottom: 0;
    }
}