.blog {
    display: flex;
    flex-direction: column;
    width: 100%;

    // banner and latest blog
    .banner {
        display: flex;
        flex-direction: column;
        background-color: #030314;
        width: 100%;
        position: relative;
        background-image: url(../../../public/assets/images/blog/banner.png);
        background-repeat: no-repeat;
        background-size: cover;

        >div:first-child {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 151px 0px 85px;

            p:first-child {
                text-align: center;
                max-width: 700px;
                font-size: 55px;
                font-weight: 700;
                line-height: 142.1%;
                color: white;
                margin: 0;

                .minutes {
                    background: linear-gradient(270.27deg, #3CC8EB 12.14%, #1158B5 37.04%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    background-clip: text;
                    text-fill-color: transparent;
                }
            }

            p:nth-child(2) {
                text-align: center;
                max-width: 400px;
                margin-top: 50px;
                font-size: 16px;
                font-weight: 400;
                line-height: 27.3px;
                color: white;
                margin-bottom: 0;
            }

            div {
                display: flex;
                flex-direction: row;
                gap: 41.5px;
                margin-top: 21px;
                justify-content: center;

                button {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    outline: none;
                    border-radius: 36px;
                    height: 52px;
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 20px;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    padding: 0px 33px;
                }

                button:hover {
                    opacity: 0.8;
                }

                button:first-child {
                    border: 1px solid #000000;
                    background: linear-gradient(98.88deg, #8C01FA -2.99%, #000000 102.28%);
                }

                button:nth-child(2) {
                    border: 1px solid #FFFFFF;
                    background: #000000;
                }
            }
        }

        // Carousel
        >div:nth-child(2) {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 28px 0% 96px;
            gap: 44px;

            >div:nth-child(1) {
                display: flex;
                justify-content: space-between;
                width: 100%;
                align-items: flex-start;
                padding: 0px 10%;

                // Latest blog
                >div:nth-child(1) {
                    display: flex;
                    flex-direction: column;
                    gap: 14px;

                    p {
                        margin: 0px;
                        font-size: 35px;
                        font-weight: 700;
                        line-height: 130%;
                        color: white;
                    }
                }


                // Arrow
                >div:nth-child(2) {
                    display: flex;
                    gap: 12px;
                    align-items: center;

                    button {
                        border: none;
                        background: transparent;
                        outline: none;
                    }

                    button:hover {
                        opacity: 0.9;
                    }
                }
            }

            >div:nth-child(2) {
                display: flex;
                width: 100%;
                padding: 28px 10% 0px;
                background: #030314;
            }

            .carouselItem {
                display: flex;
                width: 100%;
                flex-direction: row;
                gap: 59px;
            }

        }
        
    }
    
    // feature news
    .featured {
        padding: 62px 5% 60px 5%;

        >div:first-child {
            display: flex;
            flex-direction: column;

            p:first-child {
                margin: 0;
                color: #000000;
                font-size: 40px;
                font-weight: 600;
                line-height: 142.1%;
            }
        }

        >div:nth-child(2) {
            padding: 45px 0px;
            display: flex;
            // TODO:
            // Later Uncomment align-items: flex-start;
            // And Comment out align-items: center;

            // align-items: flex-start;
            align-items: center;
            gap: 41px;

            img {
                width: 40%;
                height: 400px;
                border-radius: 29px;
                object-fit: cover;
            }

            >div:nth-child(2) {
                display: flex;
                flex-direction: column;
                gap: 30px;
                // TODO:
                // later Uncomment margin-top: auto;
                // margin-top: auto;

                // title
                >p:first-child {
                    margin: 0;
                    color: #000000;
                    font-size: 28px;
                    font-weight: 600;
                    line-height: 118%;
                }

                // description
                >div:nth-child(2) {
                    //     display: flex;
                    //     gap: 5px;

                    p {
                        margin: 0;
                        color: #5C5C5C;
                        line-height: 121%;
                        font-size: 18px;
                        font-weight: 400;

                        display: -webkit-box;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                    }
                }

                // profile
                >div:nth-child(3) {
                    display: flex;
                    gap: 24px;

                    img {
                        width: 75px;
                        height: 75px;
                        border-radius: 50%;
                    }

                    >div:nth-child(2) {
                        display: flex;
                        flex-direction: column;

                        p:first-child {
                            margin: 0;
                            color: #111111;
                            font-size: 25px;
                            line-height: 130%;
                            font-weight: 600;
                        }

                        p:nth-child(2) {
                            margin: 0;
                            color: #707070;
                            font-size: 17px;
                            line-height: 130%;
                            font-weight: 400;
                        }

                    }
                }

            }
        }

        >div:nth-child(3) {
            .card {
                display: flex;
                flex-direction: column;
                gap: 13px;

                img {
                    border-radius: 29px;
                    width: 100%;
                    height: 300px;
                    object-fit: cover;
                }

                // name
                .topic {
                    margin: 0;
                    font-weight: 500;
                    font-size: 30px;
                    line-height: 130%;
                    max-width: fit-content;
                    background: linear-gradient(90deg, #1877F2 5.46%, rgba(218, 140, 255, 0.68) 77.01%);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    display: -webkit-box;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 1;
                }

                // title
                p:nth-child(3) {
                    margin: 0;
                    color: #2E2E2E;
                    font-weight: 600;
                    font-size: 19px;
                    line-height: 117%;
                    display: -webkit-box;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                }

                // description
                >div:nth-child(4) {
                    p {
                        margin: 0;
                        color: #6A6A6A;
                        font-weight: 400;
                        font-size: 19px;
                        line-height: 117%;
                        display: -webkit-box;
                        overflow: hidden;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                    }
                }

                // profile
                div:nth-child(5) {
                    display: flex;
                    gap: 24px;

                    img {
                        width: 75px;
                        height: 75px;
                        border-radius: 50%;
                    }

                    div:nth-child(2) {
                        display: flex;
                        flex-direction: column;

                        p:first-child {
                            margin: 0;
                            color: #000000;
                            font-size: 25px;
                            line-height: 130%;
                            font-weight: 500;
                        }

                        p:nth-child(2) {
                            margin: 0;
                            color: #707070;
                            font-size: 17px;
                            line-height: 130%;
                            font-weight: 400;
                        }

                    }
                }

            }
        }
    }

    // join our news letter
    .newsLetter {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-image: url(../../../public/assets/images/blog/newsLetter.png);
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 75px;
        padding: 70px 163px;
        margin: 10px 5% 50px;
        height: 100%;

        p:first-child {
            color: #FFFFFF;
            font-weight: 600;
            font-size: 40px;
            margin: 0;
            text-align: center;
        }

        p:nth-child(2) {
            font-weight: 500;
            font-size: 17px;
            line-height: 35px;
            color: #FFFFFF;
            margin-top: 41px;
            margin-bottom: 50px;
            text-align: center;
        }

        // subscribe now
        div {
            display: flex;
            padding: 3px;
            border-radius: 18px;
            align-items: center;
            // background-color: #FFFFFF;

            input {
                width: 100%;
                background: transparent;
                outline: none;
                border: none;
                font-weight: 600;
                font-size: 16px;
                line-height: 35px;
                color: black;
                text-align: start;
            }

            input::placeholder {
                color: #A7A7A7;

            }

            button {
                background: linear-gradient(259.6deg, #0066FF 14.34%, #00C2FF 84.69%);
                border-radius: 18px;
                font-weight: 600;
                font-size: 16px;
                line-height: 35px;
                text-align: center;
                color: #FFFFFF;
                outline: none;
                border: none;
                padding: 10px 20px;
                white-space: nowrap;
            }

            button:hover {
                background: linear-gradient(45deg, rgba(17, 113, 216, 1) 0%, rgba(60, 200, 235, 1) 100%);
                color: #fff;
            }
        }
    }

}

@media screen and (max-width: 991.98px) {
    .blog {
        .banner {
            >div:first-child {
                padding: 20px;

                p:first-child {
                    font-size: 32px;
                }

                p:nth-child(2) {
                    margin-top: 20px;
                }

                div {
                    gap: 20px;
                    margin-top: 21px;

                    button {
                        height: unset;
                        font-size: 12px;
                        padding: 10px 33px;
                    }
                }
            }

            // Carousel
            >div:nth-child(2) {
                // padding: 28px 0% 96px;
                gap: 20px;

                >div:nth-child(1) {
                    flex-direction: column;
                    // align-items: flex-start;
                    padding: 0px 20px;
                    gap: 20px;

                    // Latest Blog
                    >div:nth-child(1) {
                        gap: 5px;

                        p {
                            font-size: 28px;
                        }
                    }

                    // Arrow
                    >div:nth-child(2) {
                        justify-content: space-between;
                        width: 100%;
                    }
                }

                >div:nth-child(2) {
                    padding: 28px 20px 0px;
                }

                .carouselItem {
                    gap: 20px;
                }
            }
        }

        .featured {
            padding: 30px 20px;

            >div:first-child {

                p:first-child {
                    font-size: 30px;
                }
            }

            >div:nth-child(2) {
                padding: 20px 0px;
                gap: 20px;
                flex-direction: column;

                img {
                    width: 100%;
                }

                >div:nth-child(2) {
                    >p:first-child {
                        font-size: 20px;
                    }

                    >div:nth-child(3) {
                        gap: 18px;

                        img {
                            width: 50px;
                            height: 50px;
                        }

                        >div:nth-child(2) {

                            p:first-child {
                                font-size: 18px;
                            }

                            p:nth-child(2) {
                                font-size: 12px;
                            }

                        }
                    }

                }
            }

            >div:nth-child(3) {
                .card {

                    div:nth-child(5) {
                        gap: 18px;

                        img {
                            width: 50px;
                            height: 50px;
                        }

                        div:nth-child(2) {

                            p:first-child {
                                font-size: 18px;
                            }

                            p:nth-child(2) {
                                font-size: 12px;
                            }

                        }
                    }


                }
            }
        }

        .newsLetter {
            background-size: cover;
            padding: 20px;
            margin: 30px 20px;
            border-radius: 30px;

            p:first-child {
                font-size: 20px;
                line-height: unset;
            }

            p:nth-child(2) {
                font-size: 13px;
                line-height: 25px;
            }

            div {
                width: 100%;
                flex-direction: column;
                gap: 10px;
                padding: 10px 0px;

                button: {
                    width: 100%;
                    min-width: 100%;
                    margin: unset;
                }
            }
        }

    }
}