.background {
    // margin-top: 30px;
    padding-top: 90px;
    height: 100%;
    background: linear-gradient(60deg,#002e70 0%, #000100 60%, #390057 100%);
    // background-image: url("../../components/Assets/Img/Banner.webp");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    position: relative;
    .float {
        display: none;
        position: absolute;
        top: 5%;
        left: 5%;
        h2 {
            margin: 0;
            color: #CAD3F1;
            font-size: 20px;
            font-weight: bold;
        }
        img{
            width: 150px;
        }
        .imageWrapper{
            background-color: white;
            border-radius: 50%;
        }

        &:nth-of-type(2) {
            top: 30%;
            left: -5%;
        }

        &:nth-of-type(3) {
            top: 56%;
            left: 1%;
        }
    }
}
.heading_container {
    margin: auto;
    width: 98%;
    position: relative;
    top: 20%;

    h5 {
        color: white;
        text-align: center;
        font-weight: bold;
    }

    h4 {
        color: #8D8D9D;
        text-align: center;
        font-size: 13px;
        padding-top: 30px;
        max-width: 90%;
        margin: auto;
    }

    h1 {
        font-family: "tajawal" !important;
        color: white;
        text-align: center;
        font-size: 45px;
        // font-weight: bold;
        padding-top: 20px;
        line-height: 3rem;

        &:nth-of-type(2) {
            background: linear-gradient(267.98deg, #0047FF 3.72%, #3FD1FF 96.2%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;
        }
    }
    .button {
        text-align: center;
        padding: 20px 0 110px 0;
    }

}
.container{
    min-height: 100vh;
}

.marquee_container {
    transform: rotate(90deg);
    position: absolute !important;
    height: 10rem !important;
    top: 0;
    z-index: 2 !important;
    right: -630px;
    color: #0047FF;
    opacity: 0.8;
    pointer-events: none !important;
    &:nth-of-type(2){
        right: -790px;
    }
    &:nth-of-type(3){
        right: -950px;
    }
    span{
        div{
            justify-content: center !important;
            gap: 10px !important;
        }
    }
    .marquee_items {
        border-radius: 10px;
        width: 100%;
        div {
            width: 150px;
            height: 150px;
            object-fit: cover;
        }
        img{
            border-radius: 30%;
            width: 100%;
            object-fit: contain;
            padding: 0 2px;
        }
    }
}

.typewriter {
    font-family: "Tajawal" !important;
    color: white;
    font-size: 30px;
    font-weight: bold;
    background: linear-gradient(267.98deg, #0047FF 3.72%, #3FD1FF 96.2%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-left: 15px;
    // margin-top: 30px !important;
}

@media (min-width: 768px) {
    .heading_container {
        width: 95%;
        padding-top: 40px;
        // margin-top: 40px;
        h1,
        .typewriter {
            font-size: 65px;
        }

        h4 {
            font-size: 1.25rem;
        }
    }
}

@media screen and (min-width: 1024px) {
    
    .heading_container{
        h4{
            width: 70%;
        }
    }
}

@media screen and (min-width: 1200px) {
    .background {
        padding-top: 50px;
    } 
    .background {
        .floating {
            .float {
                display: flex;
            }
        }
    }
    .background {
        .float {
            top: 10%;
            left: 8%;

            h2 {
                font-size: 25px;
            }

            img {
                width: 150px;
            }

            &:nth-of-type(2) {
                top: 40%;
                left: 0%;
            }

            &:nth-of-type(3) {
                top: 65%;
                left: 8%;
            }
        }
    }
   
}

@media screen and (min-width: 1600px) {
 
    .background {
        padding-top: 100px;
    } 
    .heading_container {
        h1,
        .typewriter {
            font-size: 90px !important;
        }

        h4 {
            font-size: 1.5rem !important;
        }
    }
    .background {
        .float {
            top: 5%;
            left: -10%;

            h2 {
                font-size: 25px;
            }

            img {
                width: 200px;
            }

            &:nth-of-type(2) {
                top: 35%;
                left: -18%;
            }

            &:nth-of-type(3) {
                top: 65%;
                left: -8%;
            }
        }
    }
}

@media screen and (min-height: 775px){
    .container{
        min-height: unset;
    }
}

@media (min-width:1800px) {
    .container{
        min-height: unset;
    }
}