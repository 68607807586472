.wrapper {
  display: flex;
  overflow: hidden;
  direction: rtl;
  font-family: "Tajawal";

  * {
    font-family: "Tajawal";
  }
  p {
    color: black;
  }
  .backlink-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    a {
      color: rgb(2, 188, 234);
    }
  }
  .error-message {
    color: red;
    font-size: 0.7em;
    font-weight: 500;
  }
  .respone-error-message {
    color: red;
  }

  .left-content {
    height: 100vh;
    width: 100%;
    overflow: auto;
    padding: 5% 0;
    min-width: 300px;
    min-height: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow-x: scroll;

    align-items: center;
  }

  .left {
    width: 60%;
  }
  .right {
    overflow: hidden;

    width: 40%;
    position: relative;
    height: 100vh;

    img {
      position: relative;
      height: 100%;

      width: 100%;
      z-index: 0;
      object-fit: cover;
    }
    .overlay,
    .side-text {
      z-index: 5;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .overlay {
      background-color: rgba($color: #000000, $alpha: 0.4);
    }
    .side-text {
      z-index: 10;
      display: flex;
      align-items: center;
      justify-content: center;
      h4 {
        color: white;
        font-size: 3em;
        font-weight: 600;
      }
    }
  }
}

.left {
  .logo {
    width: 40px;
    height: 40px;
  }

  h3,
  p {
    padding: 0;
    margin: 0;
  }
  h3 {
    font-weight: bold;
  }
}

.form {
  width: 90%;
  max-width: 400px;
}
.textfield:focus {
  border: 2px solid rgba(60, 200, 235, 1);
  box-shadow: 0 0 0 transparent;
}

.textfield-error,
.textfield-error:focus {
  transition: all 0 ease-in-out;
  border: 1px solid red;
  box-shadow: 0 0 0 transparent;
}
.forgot-password {
  width: 100%;
  display: inline-block;
  text-align: right;
  color: black;
  font-weight: 600;
  font-size: 0.7em;

  &:hover {
    color: black;
    cursor: pointer;
  }
}

.continue-with-wrapper {
  $greyColor: rgba(
    $color: grey,
    $alpha: 0.33,
  );
  display: flex;
  color: $greyColor;
  justify-content: space-between;
  align-items: center;

  .separator {
    height: 1px;
    width: 30%;
    background-color: $greyColor;
  }
  p {
    font-weight: 600;
    font-size: 13px;
    white-space: nowrap;
    padding: 0 1em;
    color: $greyColor;
  }
}

@media only screen and (max-height: 600px) {
  .wrapper {
    .left {
      justify-content: initial;
    }
  }
}

// @media only screen and (max-width: 300px) {
//   .wrapper {
//     .left {
//       align-items: initial;
//     }
//   }
// }
@media only screen and (max-width: 750px) {
  .wrapper {
    overflow-x: auto;

    .left {
      width: 100%;
    }
    .right {
      display: none;
    }
  }
}

.backlink-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  p {
    color: rgb(2, 188, 234);
  }
}

@media only screen and (max-width: 1000px) {
  .wrapper {
    .right {
      .side-text {
        h4 {
          font-size: 2em;
        }
      }
    }
  }
}
